import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { theme } from 'twin.macro'

import type { ButtonProps } from './types'

export const Button = ({
	text,
	icon,
	type = 'button',
	className,
	styleType = 'primary',
	disabled = false,
	isLoading = false,
	onClick
}: ButtonProps) => {
	return (
		<StyledButton
			type={type}
			className={className}
			disabled={disabled || isLoading}
			styleType={styleType}
			onClick={onClick}
			withIcon={!!icon}>
			<Content>
				{!isLoading ? text : <Spinner icon={faSpinner} />}{' '}
				{icon && <StyledFontAwesomeIcon icon={icon} />}
			</Content>
		</StyledButton>
	)
}

const StyledButton = styled.button<{ styleType: string; withIcon: boolean; disabled: boolean }>`
	position: relative;
	border-radius: 6px;
	padding-top: ${({ withIcon }) => (withIcon ? '6px ' : '6px')};
	padding-bottom: ${({ withIcon }) => (withIcon ? '6px ' : '6px')};
	padding-left: ${({ withIcon }) => (withIcon ? '36px ' : '6px')};
	padding-right: ${({ withIcon }) => (withIcon ? '36px ' : '6px')};

	${({ styleType, disabled }) => {
		switch (styleType) {
			case 'primary':
				return `
						background-color: ${!disabled ? theme`colors.accent` : theme`colors.spindle`};
						color: ${theme`colors.white`};
				`
			case 'cancel':
				return `
					background-color: ${theme`colors.fiord`};
					color: ${theme`colors.white`};
				`
			case 'delete':
				return `
						background-color: ${theme`colors.red`};
						color: ${theme`colors.white`};
				`
		}
	}}

	&:focus {
		outline: none;
	}
`

const Content = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 1rem;
`

const Spinner = styled(FontAwesomeIcon)`
	animation-name: spin;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	font-size: 1.5rem;
	min-width: 60px;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`
