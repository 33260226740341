import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders, faArrowDownShortWide } from '@fortawesome/free-solid-svg-icons'
import { theme } from 'twin.macro'

interface IMobileFilters {
	openSort?: () => void
	openFilters?: () => void
}

const MobileFilters = ({ openSort, openFilters }: IMobileFilters) => {
	return (
		<FiltersIconsMobile>
			{openSort && (
				<FilterIcon onClick={openSort}>
					<FontAwesomeIcon icon={faArrowDownShortWide} />
					<span>Sort by</span>
				</FilterIcon>
			)}
			{openFilters && (
				<FilterIcon onClick={openFilters}>
					<FontAwesomeIcon icon={faSliders} />
					<span>Filter</span>
				</FilterIcon>
			)}
		</FiltersIconsMobile>
	)
}

const FiltersIconsMobile = styled.div`
	padding: 8px 20px;
	display: flex;
	gap: 24px;
	color: #535353;
	background-color: #e3e3e3;
	width: 100%;
	justify-content: space-between;

	@media (min-width: ${theme`screens.md`}) {
		background-color: transparent;
		padding: 0;
		width: 50%;
	}

	@media (min-width: ${theme`screens.lg`}) {
		display: none;
	}
`

const FilterIcon = styled.button`
	background-color: #fff;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	font-size: 11px;
	padding: 8px;
	gap: 10px;
	color: #535353;
	font-weight: 600;
	border: 1px solid #dcdcdc;
`

export default MobileFilters
