import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Wrapper = styled.div`
	padding: 0 1rem;
	@media (min-width: ${theme`screens.lg`}) {
		padding: 0;
	}
`
export const Row = styled.div`
	gap: 10px;
	display: flex;
	flex-direction: column;

	@media (min-width: ${theme`screens.md`}) {
		justify-content: space-between;
		flex-direction: row;

		& > * {
			flex: 1;
		}
	}
`

export const Fieldset = styled.fieldset`
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 10px;
`

export const Legend = styled.legend`
	/* border: 1px solid lightgray; */
`
