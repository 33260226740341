import styled from 'styled-components'
import { theme } from 'twin.macro'

interface IFormSubtitle {
	title: string
	hasBorder?: boolean
}

const FormSubtitle = ({ title, hasBorder = true }: IFormSubtitle) => {
	return <Title hasBorder={hasBorder}>{title}</Title>
}

const Title = styled.h4<{ hasBorder: boolean }>`
	padding: ${({ hasBorder }) => (hasBorder ? '0 10px' : '0')};
	color: ${theme`colors.black`};
	font-weight: 600;
	margin-left: ${({ hasBorder }) => (hasBorder ? '10px' : '0')};

	${({ hasBorder }) => hasBorder && `border-left: 5px solid ${theme`colors.horizon`};`}
`

export default FormSubtitle
