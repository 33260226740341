import styled from 'styled-components'

const Dot = styled.div<{ color: string }>`
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${({ color }) => color};
`

export default Dot
