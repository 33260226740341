import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { theme } from 'twin.macro'

interface ICtaLink {
	text: string
	to: string
	icon?: boolean
	className?: string
}

const CtaLink = ({ text, to, className, icon = true }: ICtaLink) => {
	return (
		<StyledNavLink to={to} className={className}>
			<span>{text}</span> {icon && <BtnIcon icon={faPlus} />}
		</StyledNavLink>
	)
}

const StyledNavLink = styled(NavLink)`
	color: #fff;
	background-color: #465cff;
	border-radius: 6px;
	padding: 10px;
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
	min-width: 136px;
	font-weight: 900;
	font-family: Lato, sans-serif;
	font-size: 14px;
	line-height: 16px;
	gap: 10px;
	white-space: nowrap;
`

const BtnIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 10px;

	@media (min-width: ${theme`screens.md`}) {
		position: static;
	}
`

export default CtaLink
