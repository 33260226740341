import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks'

const useRefreshToken = () => {
	const navigate = useNavigate()
	const { setAuth } = useAuth()

	const refresh = async () => {
		try {
			const response = await axios.post('/refresh/', {
				refresh: localStorage.getItem('refresh')
			})

			setAuth((prev: any) => ({
				...prev,
				accessToken: response.data.access
			}))

			return response.data.access
		} catch (err) {
			setAuth({})
			navigate('/login')
		}
	}

	return refresh
}

export default useRefreshToken
