import { toast } from 'react-toastify'

interface IUseToast {
	type: 'success' | 'error'
	message: string
}

export const useToast = ({ type, message }: IUseToast) => {
	return () => toast[type](message)
}
