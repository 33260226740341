import { useInfiniteQuery } from 'react-query'

import { useAxiosPrivate } from 'hooks'

interface IUseFetchInfinite {
	name: string
	endpoint: string
	enabled?: boolean
	options?: any
	query?: string
}

export const useFetchInfinite = ({
	name,
	endpoint,
	query,
	enabled = true,
	options
}: IUseFetchInfinite) => {
	const axiosPrivate = useAxiosPrivate()

	const fetchData = async ({ pageParam }: { pageParam: string }) => {
		const res = await axiosPrivate.get(
			`${endpoint}${pageParam ? `?cursor=${pageParam}` : ''}${query ? `?${query}` : ''}`
		)

		return res.data
	}

	const { data, isLoading, fetchNextPage } = useInfiniteQuery(
		name,
		async ({ pageParam }) => fetchData({ pageParam }),
		{
			getNextPageParam: (lastPage) => {
				let page

				if (lastPage.next) {
					const url = new URL(lastPage?.next)
					page = url.searchParams.get('cursor')!
				}

				return page || undefined
			},
			enabled,
			refetchOnWindowFocus: false,
			...options
		}
	)

	return {
		data,
		isLoading,
		fetchNextPage
	}
}
