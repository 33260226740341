export async function getBase64FromUrl(url: string) {
	const data = await fetch(url)
	const blob = await data.blob()
	return new Promise((resolve) => {
		const reader = new FileReader()
		reader.readAsDataURL(blob)
		reader.onloadend = () => {
			const base64data = reader.result
			resolve(base64data)
		}
	})
}

export function dataURLtoFile(dataurl: string, filename: string) {
	const arr = dataurl.split(',') as any
	const mime = arr[0].match(/:(.*?);/)[1]
	const bstr = atob(arr[1])
	let n = bstr.length
	const u8arr = new Uint8Array(n)

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}

	return new File([u8arr], filename, { type: mime })
}
