import ModalEl from 'react-modal'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { Header, Title, BtnIcon } from './styles'

import type { IModal } from './types'

if (process.env.NODE_ENV !== 'test') ModalEl.setAppElement('#root')

export const Modal = ({ children, title, isOpened, customStyles, closeModal }: IModal) => {
	return (
		<ModalEl
			isOpen={isOpened}
			style={customStyles}
			onRequestClose={closeModal}
			ariaHideApp={process.env.NODE_ENV !== 'test'}>
			<Header>
				{title && <Title>{title}</Title>}
				<BtnIcon onClick={closeModal} icon={faTimes} />
			</Header>
			{children}
		</ModalEl>
	)
}
