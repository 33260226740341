import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Wrapper = styled.div`
	display: flex;
	gap: 10px;
`

export const IconContainer = styled.span<{ isActive?: boolean }>`
	width: 1.25rem;
	color: ${({ isActive }) => (isActive ? theme`colors.accent` : theme`colors.grayChateau`)};
`
