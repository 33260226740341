import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'

interface IStatus {
	active: boolean
}

const Status = ({ active }: IStatus) => {
	return <span>{active ? <CheckIcon icon={faCheck} /> : <TimesIcon icon={faTimes} />}</span>
}

const CheckIcon = styled(FontAwesomeIcon)`
	color: #4cac56;
`

const TimesIcon = styled(FontAwesomeIcon)`
	color: #be4242;
`

export default Status
