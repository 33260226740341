import { ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from 'twin.macro'

interface IFiltersWrapper {
	children: ReactNode
	className?: string
}

const FiltersWrapper = ({ children, className }: IFiltersWrapper) => {
	return <Wrapper className={className}>{children}</Wrapper>
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	align-items: center;
	gap: 6px;
	padding-bottom: 10px;

	@media (min-width: ${theme`screens.md`}) {
		flex-direction: row-reverse;
		padding: 12px 24px;
		flex-wrap: nowrap;
	}

	@media (min-width: ${theme`screens.lg`}) {
		align-items: flex-end;
		flex-direction: row;
		padding: 12px 0;
		width: 100%;
	}
`

export default FiltersWrapper
