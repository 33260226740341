import { StyledLabel, StyledIndicator } from './styles'

import type { TLabel } from './types'

const Label = ({ children, htmlFor, disabled = false, required = false }: TLabel) => {
	return (
		<StyledLabel htmlFor={htmlFor} disabled={disabled}>
			{children}
			{required && <StyledIndicator>*</StyledIndicator>}
		</StyledLabel>
	)
}

export default Label
