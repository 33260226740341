import tw from 'twin.macro'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Group = tw.div`
	flex
	flex-col
	gap-y-2.5
`

export const FieldWrapper = styled.div`
	display: flex;
	align-items: center;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	gap: 10px;
	position: relative;
`

export const StyledTextarea = styled.textarea<{ withIcon: boolean }>`
	font-size: 12px;
	border-radius: 8px;
	width: 100%;
	padding: ${({ withIcon }) => (withIcon ? '10px 10px 10px 45px' : '10px')};

	&:focus {
		outline: none;
		box-shadow: 0 0 5px #465cff;
	}
`

export const IconWrapper = styled.div`
	position: absolute;
	left: 0;
	width: 39px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px 0 0 8px;
	color: #949494;
	top: 0;
	bottom: 0;
	font-size: 14px;
	background-color: #e3e3e3;
`

export const Icon = styled(FontAwesomeIcon)``

export const WordCount = styled.span`
	font-size: 12px;
`

export const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`
