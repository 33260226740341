import FormTabs from 'components/Ui/Form/Tabs'
import { IForm } from './types'
import { StyledForm, FormContent, StyledButton } from './styles'

const Form = ({
	children,
	submitText,
	tabs,
	activeTab,
	className,
	isLoading,
	disabled,
	hideBorderTop = false,
	setActiveTab,
	onSubmit
}: IForm) => {
	return (
		<StyledForm onSubmit={onSubmit} className={className}>
			{tabs && <FormTabs tabs={tabs} changeTab={setActiveTab} activeTab={activeTab!} />}
			<FormContent hideBorderTop={hideBorderTop}>
				{children}
				{submitText && !disabled && (
					<StyledButton type="submit" text={submitText} isLoading={isLoading} />
				)}
			</FormContent>
		</StyledForm>
	)
}

export default Form
