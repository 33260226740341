import { useState, useEffect } from 'react'

export const useModal = () => {
	const [isOpen, setIsOpen] = useState(false)

	const openModal = () => setIsOpen(true)

	const closeModal = () => setIsOpen(false)

	useEffect(() => {
		isOpen ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset')
	}, [isOpen])

	return {
		isOpen,
		openModal,
		closeModal
	}
}
