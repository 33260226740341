import styled from 'styled-components'
import tw, { theme } from 'twin.macro'

import Table from 'components/Ui/Table'
import SearchInput from 'components/Ui/SearchInput'
import { Selects } from 'components/Filters'
import Select from 'components/Ui/Select'
import Filters from 'components/Filters'
import SortSelect from 'components/Ui/Select/Sort'
import Header from 'components/Header'
import Table2 from 'components/Ui/Table2'
import TableImage from 'components/Ui/Table/Image'
import { NavLink } from 'react-router-dom'
import {
	Header as TableHeader,
	Container as TableContainer,
	List as TableList
} from 'components/Ui/Table'

export const StyledTable = styled(Table)`
	${TableHeader} {
		grid-template-columns: 13px 1fr repeat(7, 0.5fr) 0.5fr 0.25fr 15px;

		@media (min-width: ${theme`screens.md`}) {
			width: 1600px;
		}

		& > *:nth-child(1) {
			text-align: left;
		}
	}

	${TableContainer} {
		overflow-x: auto;
		margin-bottom: 35px;
	}

	${TableList} {
		margin-bottom: 0;

		@media (min-width: ${theme`screens.md`}) {
			width: 1600px;
		}
	}
`

export const StyledSearchInput = styled(SearchInput)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const DesktopSelect = styled(Select)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const CtaContainer = styled.div`
	width: 100%;
	padding: 0 20px;

	@media (min-width: ${theme`screens.md`}) {
		width: auto;
		padding: 0;
		margin-left: auto;
	}
`

export const DesktopCtaContainer = styled.div`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		width: auto;
		padding: 0;
		margin-left: auto;
		display: block;
	}
`

export const ModalFilters = styled(Filters)`
	display: flex;

	${Selects} {
		width: 100%;

		& > div {
			width: 100%;
		}
	}
`

export const FiltersModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`

export const DesktopSortSelect = styled(SortSelect)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: block;
	}
`

export const MobileCtaContainer = styled(CtaContainer)`
	@media (min-width: ${theme`screens.lg`}) {
		display: none;
	}
`

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

export const StyledHeader = styled(Header)`
	width: 100%;
`

export const StyledTable2 = styled(Table2)`
	height: 65vh;

	@media (min-width: ${theme`screens.md`}) {
		height: calc(100vh - 59px - 164px);
	}

	@media (min-width: ${theme`screens.lg`}) {
		height: calc(100vh - 2rem - 27px - 90px - 205px);
	}

	@media (min-width: 1667px) {
		height: calc(100vh - 2rem - 27px - 215px);
	}
`

export const ImageElement = styled.div`
	display: flex;
	gap: 18px;
	text-align: left;
	align-items: center;
`

export const InfoDetails = styled.div`
	display: flex;
	flex-direction: column;
`

export const Sku = styled.span`
	font-size: 0.75rem;
`

export const StyledTableImage = styled(TableImage)`
	align-items: center;
	display: flex;
`

export const Name = styled(NavLink)`
	font-size: 1rem;
`

export const PrintContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 12px;
	// width: 38mm;
	// height: 23mm;
	width: 142px;
	height: 82px;
	page-break-before: always;

	@media screen {
		display: none;
	}
`

export const UserNameCell = styled.div`
	display: flex;
	flex-direction: column;
`

export const UserEmail = styled.span`
	font-size: 0.75rem;
`

export const BookingCell = styled.div`
	display: flex;
	flex-direction: column;
`

export const BookingReference = styled.div`
	font-size: 0.75rem;
`

export const PrintQuantityInput = styled.input`
	width: 4em;
	text-align: center;
`

export const BulkPrintItemRow = tw.div`grid grid-cols-3 gap-2 [&:not(:last-child)]:border-b py-1`

export const BulkPrintItems = tw.div`overflow-auto max-h-[500px] mt-2`

export const BulkPrintHeader = tw.div`grid grid-cols-3 font-bold gap-2`

export const BulkPrintHeaderPrint = tw.span`pl-4`
