import styled from 'styled-components'
import { theme } from 'twin.macro'

import FiltersWrapper from 'components/FiltersWrapper'
import Table from 'components/Ui/Table'
import CtaLink from 'components/Ui/CtaLink'
import Header from 'components/Header'
import Table2 from 'components/Ui/Table2'
import { Header as TableHeader } from 'components/Ui/Table'
import SearchInput from 'components/Ui/SearchInput'

export const StyledTable = styled(Table)`
	${TableHeader} {
		grid-template-columns: 13px repeat(2, 1fr) 15px;

		& > span {
			text-align: left;
		}
	}
`

export const StyledFiltersWrapper = styled(FiltersWrapper)`
	justify-content: space-between;
	padding: 12px 24px;

	@media (min-width: ${theme`screens.lg`}) {
		padding: 12px 0;
	}
`

export const DesktopCtaLink = styled(CtaLink)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: block;
	}
`

export const StyledHeader = styled(Header)<{ needMarginBottom: boolean }>`
	${({ needMarginBottom }) => needMarginBottom && 'margin-bottom: 2rem'};
	width: 100%;
`

export const HeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

export const CtaContainer = styled.div`
	width: 100%;

	@media (min-width: ${theme`screens.md`}) {
		width: auto;
		padding: 0;
		margin-left: auto;
	}
`

export const MobileCtaContainer = styled(CtaContainer)`
	@media (min-width: ${theme`screens.lg`}) {
		display: none;
	}
`

export const DesktopCtaContainer = styled.div`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		width: auto;
		padding: 0;
		margin-left: auto;
		display: block;
	}
`

export const StyledTable2 = styled(Table2)`
	height: 65vh;

	@media (min-width: ${theme`screens.md`}) {
		height: calc(100vh - 59px - 164px);
	}

	@media (min-width: ${theme`screens.lg`}) {
		height: calc(100vh - 2rem - 27px - 90px - 125px);
	}

	@media (min-width: 1667px) {
		height: calc(100vh - 2rem - 27px - 215px);
	}
`

export const StyledSearchInput = styled(SearchInput)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const FiltersContainer = styled.div`
	display: flex;
	gap: 20px;
`
