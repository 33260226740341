"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  Editor: () => Editor,
  EditorContent: () => EditorContent
});
module.exports = __toCommonJS(src_exports);

// src/editor.tsx
var import_react6 = require("react");
var import_react_konva7 = require("react-konva");
var import_styled_components2 = __toESM(require("styled-components"));
var import_date_fns = require("date-fns");
var import_react_fontawesome = require("@fortawesome/react-fontawesome");
var import_free_solid_svg_icons = require("@fortawesome/free-solid-svg-icons");
var import_react_i18next = require("react-i18next");

// src/components/editor/Image.tsx
var import_react = require("react");
var import_use_image = __toESM(require("use-image"));
var import_react_konva = require("react-konva");
var import_jsx_runtime = require("react/jsx-runtime");
var EditorImage = ({
  id,
  x,
  y,
  rotation,
  sampleImage,
  isAdmin,
  isSelected,
  onDragEnd,
  onContextMenu,
  onClick,
  onTransformEnd
}) => {
  const [image] = (0, import_use_image.default)(sampleImage);
  const shapeRef = (0, import_react.useRef)(null);
  const trRef = (0, import_react.useRef)(null);
  const transformOptions = {
    resizeEnabled: false,
    rotationSnaps: [0, 90, 180, 270],
    centeredScaling: true
  };
  (0, import_react.useEffect)(() => {
    isAdmin && isSelected && trRef.current.nodes([shapeRef.current]);
  }, [isAdmin, isSelected]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva.Image, {
        image,
        id,
        ref: shapeRef,
        draggable: isAdmin,
        listening: isAdmin,
        x,
        y,
        rotation,
        onClick: isAdmin ? onClick : () => {
        },
        onDragEnd,
        onContextMenu: isAdmin ? onContextMenu : () => {
        },
        onTransformEnd,
        preventDefault: isAdmin
      }),
      isAdmin && isSelected && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva.Transformer, {
        ref: trRef,
        ...transformOptions
      })
    ]
  });
};
var Image_default = EditorImage;

// src/components/editor/ContextMenu.tsx
var import_styled_components = __toESM(require("styled-components"));
var import_jsx_runtime = require("react/jsx-runtime");
var ContextMenu = ({
  isVisible,
  coordinates,
  currentElementId,
  deleteElement
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Menu, {
    isVisible,
    coordinates,
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)("div", {
      children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(MenuButton, {
        onClick: () => deleteElement && deleteElement(currentElementId),
        children: "Delete"
      })
    })
  });
};
var Menu = import_styled_components.default.div`
  display: ${({ isVisible }) => isVisible ? "block" : "none"};
  position: absolute;
  width: 60px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
  top: ${({ coordinates }) => coordinates.y}px;
  left: ${({ coordinates }) => coordinates.x}px;
`;
var MenuButton = import_styled_components.default.div`
  padding: 5px;
  cursor: pointer;
`;
var ContextMenu_default = ContextMenu;

// src/components/editor/Grid.tsx
var import_react_konva2 = require("react-konva");
var import_jsx_runtime = require("react/jsx-runtime");
var Grid = ({ width, height }) => {
  const boothSize = 25;
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva2.Shape, {
    sceneFunc: (context, shape) => {
      const verticalCount = width / boothSize;
      const horizontalCount = height / boothSize;
      for (let i = 1; i < verticalCount; i++) {
        context.beginPath();
        context.moveTo(i * boothSize, 0);
        context.lineTo(i * boothSize, height);
        context.fillStrokeShape(shape);
      }
      for (let i = 1; i < horizontalCount; i++) {
        context.beginPath();
        context.moveTo(0, i * boothSize);
        context.lineTo(width, i * boothSize);
        context.fillStrokeShape(shape);
      }
    },
    stroke: "#ddd",
    strokeWidth: 1
  });
};
var Grid_default = Grid;

// src/components/editor/Booth.tsx
var import_react_konva3 = require("react-konva");
var import_jsx_runtime = require("react/jsx-runtime");
var Booth = ({
  isAdmin,
  onDragEnd,
  x,
  y,
  width,
  height,
  onClick,
  onTouchEnd,
  onContextMenu,
  id,
  fill,
  isClickable
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_react_konva3.Group, {
    draggable: isAdmin,
    onDragEnd,
    x,
    y,
    width,
    height,
    onClick,
    onTouchEnd,
    onContextMenu: isAdmin ? onContextMenu : () => {
    },
    id,
    listening: isClickable || isAdmin,
    onMouseEnter: isClickable ? (e) => {
      const container = e.target.getStage().container();
      container.style.cursor = "pointer";
    } : void 0,
    onMouseLeave: isClickable ? (e) => {
      const container = e.target.getStage().container();
      container.style.cursor = "default";
    } : void 0,
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva3.Rect, {
        width,
        height,
        fill,
        id
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva3.Text, {
        text: id,
        width,
        height,
        align: "center",
        verticalAlign: "middle",
        fill: "#fff",
        id,
        fontSize: 10
      })
    ]
  });
};
var Booth_default = Booth;

// src/components/editor/Rectangle.tsx
var import_react2 = require("react");
var import_react_konva4 = require("react-konva");
var import_jsx_runtime = require("react/jsx-runtime");
var Rectangle = ({
  id,
  isAdmin,
  width,
  height,
  fill,
  x,
  y,
  rotation,
  isSelected,
  onDragEnd,
  onClick,
  onContextMenu,
  onTransformEnd
}) => {
  const shapeRef = (0, import_react2.useRef)(null);
  const trRef = (0, import_react2.useRef)(null);
  const transformOptions = {
    resizeEnabled: false,
    rotationSnaps: [0, 90, 180, 270],
    centeredScaling: true
  };
  (0, import_react2.useEffect)(() => {
    isAdmin && isSelected && trRef.current.nodes([shapeRef.current]);
  }, [isAdmin, isSelected]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva4.Rect, {
        ref: shapeRef,
        width,
        height,
        fill,
        id,
        draggable: isAdmin,
        x,
        y,
        listening: isAdmin,
        rotation,
        onDragEnd,
        onClick: isAdmin ? onClick : () => {
        },
        onContextMenu: isAdmin ? onContextMenu : () => {
        },
        onTransformEnd
      }),
      isAdmin && isSelected && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva4.Transformer, {
        ref: trRef,
        ...transformOptions
      })
    ]
  });
};
var Rectangle_default = Rectangle;

// src/components/editor/Text.tsx
var import_react3 = require("react");
var import_react_konva5 = require("react-konva");
var import_jsx_runtime = require("react/jsx-runtime");
var EditorText = ({
  id,
  x,
  y,
  text,
  rotation,
  isAdmin,
  isSelected,
  onDragEnd,
  onContextMenu,
  onClick,
  onTransformEnd
}) => {
  const shapeRef = (0, import_react3.useRef)(null);
  const trRef = (0, import_react3.useRef)(null);
  const transformOptions = {
    resizeEnabled: false,
    rotationSnaps: [0, 90, 180, 270],
    centeredScaling: true
  };
  (0, import_react3.useEffect)(() => {
    isAdmin && isSelected && trRef.current.nodes([shapeRef.current]);
  }, [isAdmin, isSelected]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva5.Text, {
        ref: shapeRef,
        x,
        y,
        text,
        id,
        listening: isAdmin,
        rotation,
        draggable: isAdmin,
        onDragEnd,
        onContextMenu: isAdmin ? onContextMenu : () => {
        },
        onTransformEnd,
        onClick: isAdmin ? onClick : () => {
        }
      }, id),
      isAdmin && isSelected && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva5.Transformer, {
        ref: trRef,
        ...transformOptions
      })
    ]
  });
};
var Text_default = EditorText;

// src/components/editor/Line.tsx
var import_react4 = require("react");
var import_react_konva6 = require("react-konva");
var import_jsx_runtime = require("react/jsx-runtime");
var EditorLine = ({
  id,
  x,
  y,
  points,
  tension,
  stroke,
  strokeWidth,
  rotation,
  isAdmin,
  isSelected,
  onClick,
  onDragEnd,
  onContextMenu,
  onTransformEnd
}) => {
  const shapeRef = (0, import_react4.useRef)(null);
  const trRef = (0, import_react4.useRef)(null);
  const transformOptions = {
    resizeEnabled: false,
    rotationSnaps: [0, 90, 180, 270],
    centeredScaling: true
  };
  (0, import_react4.useEffect)(() => {
    isAdmin && isSelected && trRef.current.nodes([shapeRef.current]);
  }, [isAdmin, isSelected]);
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, {
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva6.Line, {
        id,
        ref: shapeRef,
        x,
        y,
        listening: isAdmin,
        points,
        tension,
        stroke,
        strokeWidth,
        rotation,
        draggable: isAdmin,
        onClick: isAdmin ? onClick : () => {
        },
        onDragEnd,
        onContextMenu: isAdmin ? onContextMenu : () => {
        },
        onTransformEnd
      }),
      isAdmin && isSelected && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva6.Transformer, {
        ref: trRef,
        ...transformOptions
      })
    ]
  });
};
var Line_default = EditorLine;

// src/store/FormContext.tsx
var import_react5 = require("react");
var import_jsx_runtime = require("react/jsx-runtime");
var FormContext = (0, import_react5.createContext)(null);
var initialFormState = {
  location: "",
  booth: "",
  duration: "",
  email: "",
  fullName: "",
  phone: "",
  ssn: ""
};
var reducer = (state, action) => {
  switch (action.type) {
    case action.type:
      return { ...state, [action.type]: action.value };
    default:
      return state;
  }
};
var FormContextProvider = ({
  children
}) => {
  const [step, setStep] = (0, import_react5.useState)(1);
  const [formState, dispatchFormState] = (0, import_react5.useReducer)(reducer, initialFormState);
  const [price, setPrice] = (0, import_react5.useState)("");
  const [startDate, setStartDate] = (0, import_react5.useState)(null);
  const [endDate, setEndDate] = (0, import_react5.useState)(null);
  const [locationBooths, setLocationBooths] = (0, import_react5.useState)([]);
  const [freeDays, setFreeDays] = (0, import_react5.useState)([]);
  const [storeBackground, setStoreBackground] = (0, import_react5.useState)("");
  const [floormapConfig, setFloormapConfig] = (0, import_react5.useState)([]);
  const [rentals, setRentals] = (0, import_react5.useState)([]);
  const [boothType, setBoothType] = (0, import_react5.useState)(null);
  const [chosenBooth, setChosenBooth] = (0, import_react5.useState)(null);
  const handleFormChange = ({ key, value: value2 }) => dispatchFormState({ type: key, value: value2 });
  const { location, booth, duration, email, fullName, phone, ssn } = formState;
  const value = {
    step,
    price,
    form: {
      location,
      booth,
      duration,
      email,
      fullName,
      phone,
      ssn
    },
    rentals,
    locationBooths,
    freeDays,
    storeBackground,
    floormapConfig,
    startDate,
    endDate,
    boothType,
    chosenBooth,
    setStep,
    handleFormChange,
    setPrice,
    setRentals,
    setLocationBooths,
    setFreeDays,
    setStoreBackground,
    setFloormapConfig,
    setStartDate,
    setEndDate,
    setBoothType,
    setChosenBooth
  };
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FormContext.Provider, {
    value,
    children
  });
};
var FormContext_default = FormContextProvider;

// src/editor.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var isBoothAvailable = (booth, start, end) => {
  var _a;
  if (!booth)
    return false;
  if (!(booth == null ? void 0 : booth.unavailable_dates))
    return false;
  if (!start || !end)
    return false;
  return !((_a = booth.unavailable_dates) == null ? void 0 : _a.some(
    (date) => (0, import_date_fns.isWithinInterval)((0, import_date_fns.parseISO)(date), {
      start,
      end
    })
  ));
};
var EditorContent = ({
  isAdmin,
  className,
  floormapConfig,
  locationBooths,
  startDate = new Date(),
  endDate = new Date(),
  chosenBoothColor = "orange",
  boothColor = "orange",
  unavailableBoothColor = "red",
  availableBoothColor = "green",
  secondaryAvailableBoothColor = "lightgreen",
  chosenBoothType,
  chosenBooth,
  handleDragEnd,
  transformElement,
  deleteElement,
  onChosenBooth
}) => {
  const { t } = (0, import_react_i18next.useTranslation)();
  const stageRef = (0, import_react6.useRef)(null);
  const defaultWidth = "700";
  const defaultHeight = "600";
  const [floorWidth, setFloorWidth] = (0, import_react6.useState)(defaultWidth);
  const [floorHeight, setFloorHeight] = (0, import_react6.useState)(defaultHeight);
  const [isContextMenuVisible, setIsContextMenuVisible] = (0, import_react6.useState)(false);
  const [currentElementId, setCurrentElementId] = (0, import_react6.useState)("");
  const [contextMenuCoordinates, setContextMenuCoordinates] = (0, import_react6.useState)({
    x: 0,
    y: 0
  });
  const [selectedId, setSelectedId] = (0, import_react6.useState)(null);
  const [overlayVisibility, setOverlayVisibility] = (0, import_react6.useState)(true);
  const handleLayerRightClick = (e) => {
    e.evt.preventDefault();
    const { pageX, pageY } = e.evt;
    setCurrentElementId(e.target.attrs.id);
    setContextMenuCoordinates({ x: pageX, y: pageY });
    setIsContextMenuVisible(true);
  };
  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
    }
  };
  const handleSingleBoothClick = (e) => {
    if (isAdmin)
      return false;
    const booth = Array.isArray(locationBooths) && locationBooths.length > 0 ? locationBooths.find(
      (booth2) => booth2.number === parseInt(e.target.attrs.id, 10)
    ) : null;
    if ((booth == null ? void 0 : booth.booth_type) !== chosenBoothType)
      return false;
    const isAvailable = isBoothAvailable(booth, startDate, endDate);
    if (!isAvailable)
      return false;
    if (booth == null) {
      console.log("booth not found, something went wrong");
    }
    if (booth != null) {
      onChosenBooth && onChosenBooth({ id: booth.pk, number: booth.number });
    }
  };
  (0, import_react6.useEffect)(() => {
    window.addEventListener("click", () => {
      setIsContextMenuVisible(false);
    });
  }, []);
  (0, import_react6.useEffect)(() => {
    if ((floormapConfig == null ? void 0 : floormapConfig.findIndex((config) => config.type === "floorDimentions")) !== -1) {
      const { width, height } = floormapConfig.find((config) => config.type === "floorDimentions");
      setFloorWidth(width);
      setFloorHeight(height);
    }
  }, [floormapConfig]);
  const colorBooth = (id) => {
    if (isAdmin)
      return boothColor;
    const currentBooth = locationBooths == null ? void 0 : locationBooths.find((item) => item.number === +id);
    const isAvailable = isBoothAvailable(currentBooth, startDate, endDate);
    if (!currentBooth)
      return "#000";
    if (chosenBooth.id === currentBooth.pk)
      return chosenBoothColor;
    return isAvailable ? currentBooth.booth_type === chosenBoothType ? availableBoothColor : secondaryAvailableBoothColor : unavailableBoothColor;
  };
  const isBoothClickable = (id) => {
    if (isAdmin)
      return false;
    const currentBooth = locationBooths == null ? void 0 : locationBooths.find((item) => item.number === +id);
    if ((currentBooth == null ? void 0 : currentBooth.booth_type) !== chosenBoothType)
      return false;
    return isBoothAvailable(currentBooth, startDate, endDate);
  };
  const handleTouch = (e) => {
    setOverlayVisibility(false);
  };
  return /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(Wrapper, {
    className,
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(EditorWrapper, {
        className: "editor-wrapper",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FloorOverlay, {
            isVisible: overlayVisibility && !isAdmin,
            onTouchStart: handleTouch,
            onClick: handleTouch,
            children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(IconWrapper, {
              isVisible: overlayVisibility,
              children: [
                /* @__PURE__ */ (0, import_jsx_runtime.jsx)(IconBackground, {
                  children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(SwipeIcon, {
                    icon: import_free_solid_svg_icons.faHandPointer
                  })
                }),
                /* @__PURE__ */ (0, import_jsx_runtime.jsx)(IconText, {
                  children: t("editor.overlay.text")
                })
              ]
            })
          }),
          /* @__PURE__ */ (0, import_jsx_runtime.jsx)(StyledStage, {
            width: +floorWidth,
            height: +floorHeight,
            ref: stageRef,
            id: "editor-container",
            onMouseDown: checkDeselect,
            onTouchStart: checkDeselect,
            preventDefault: isAdmin,
            children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_react_konva7.Layer, {
              preventDefault: isAdmin,
              children: [
                isAdmin && /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Grid_default, {
                  width: +floorWidth,
                  height: +floorHeight
                }),
                floormapConfig != null && Array.isArray(floormapConfig) && (floormapConfig == null ? void 0 : floormapConfig.map((element) => {
                  if (element.type === "floorDimentions") {
                    return null;
                  } else if (element.type === "booth") {
                    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Booth_default, {
                      ...element,
                      isAdmin,
                      onDragEnd: handleDragEnd,
                      onContextMenu: handleLayerRightClick,
                      onClick: handleSingleBoothClick,
                      onTouchEnd: handleSingleBoothClick,
                      fill: colorBooth(element.id),
                      isClickable: isBoothClickable(element.id)
                    }, element.id);
                  } else if (element.type === "rect") {
                    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Rectangle_default, {
                      ...element,
                      isAdmin,
                      isSelected: element.id === selectedId,
                      onDragEnd: handleDragEnd,
                      onClick: () => setSelectedId(element.id),
                      onContextMenu: handleLayerRightClick,
                      onTransformEnd: transformElement,
                      preventDefault: isAdmin
                    }, element.id);
                  } else if (element.type === "text") {
                    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Text_default, {
                      ...element,
                      isAdmin,
                      isSelected: element.id === selectedId,
                      onDragEnd: handleDragEnd,
                      onClick: () => setSelectedId(element.id),
                      onContextMenu: handleLayerRightClick,
                      onTransformEnd: transformElement
                    }, element.id);
                  } else if (element.type === "circle") {
                    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_react_konva7.Circle, {
                      x: element.x,
                      y: element.y,
                      fill: element.fill,
                      radius: element.radius,
                      id: element.id,
                      draggable: isAdmin,
                      onDragEnd: handleDragEnd,
                      listening: isAdmin,
                      onContextMenu: isAdmin ? handleLayerRightClick : () => null,
                      preventDefault: isAdmin
                    }, element.id);
                  } else if (element.type === "line") {
                    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Line_default, {
                      ...element,
                      isAdmin,
                      isSelected: element.id === selectedId,
                      onClick: () => setSelectedId(element.id),
                      onDragEnd: handleDragEnd,
                      onContextMenu: handleLayerRightClick,
                      onTransformEnd: transformElement,
                      preventDefault: isAdmin
                    }, element.id);
                  } else if (element.type === "image") {
                    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(Image_default, {
                      ...element,
                      isAdmin,
                      sampleImage: element.src,
                      isSelected: element.id === selectedId,
                      onClick: () => setSelectedId(element.id),
                      onDragEnd: handleDragEnd,
                      onContextMenu: handleLayerRightClick,
                      onTransformEnd: transformElement,
                      preventDefault: isAdmin
                    }, element.id);
                  } else {
                    return element;
                  }
                }))
              ]
            })
          })
        ]
      }),
      /* @__PURE__ */ (0, import_jsx_runtime.jsx)(ContextMenu_default, {
        isVisible: isContextMenuVisible,
        coordinates: contextMenuCoordinates,
        currentElementId,
        deleteElement
      })
    ]
  });
};
var Editor = ({
  isAdmin = false,
  className,
  floormapConfig = [],
  locationBooths = [],
  startDate,
  endDate,
  availableBoothColor,
  unavailableBoothColor,
  chosenBoothColor,
  boothColor,
  chosenBoothType,
  secondaryAvailableBoothColor,
  chosenBooth,
  handleDragEnd,
  transformElement,
  deleteElement,
  onChosenBooth
}) => {
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(FormContext_default, {
    children: /* @__PURE__ */ (0, import_jsx_runtime.jsx)(EditorContent, {
      isAdmin,
      className,
      floormapConfig,
      handleDragEnd,
      transformElement,
      deleteElement,
      locationBooths,
      startDate,
      endDate,
      onChosenBooth,
      availableBoothColor,
      unavailableBoothColor,
      chosenBoothColor,
      chosenBoothType,
      secondaryAvailableBoothColor,
      chosenBooth,
      boothColor
    })
  });
};
var Wrapper = import_styled_components2.default.div`
  display: flex;
  align-items: flex-start;
`;
var StyledStage = (0, import_styled_components2.default)(import_react_konva7.Stage)`
  & > div {
    border: 1px solid #ddd;
  }
`;
var FloorOverlay = import_styled_components2.default.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    display: none;
  }

  ${({ isVisible }) => !isVisible && "display: none;"}

  @media (min-width: 1024px) {
    display: none;
  }
`;
var IconWrapper = import_styled_components2.default.div`
  position: absolute;
  top: 50px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ isVisible }) => !isVisible && "display: none;"}

  @media (min-width: 1024px) {
    display: none;
  }
`;
var IconBackground = import_styled_components2.default.div`
  width: 150px;
  height: 150px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
`;
var swipe = import_styled_components2.keyframes`
  0%   {transform: translateX(0)}
  25%  {transform: translateX(-15px)}
  100% {transform: translateX(0)}
`;
var SwipeIcon = (0, import_styled_components2.default)(import_react_fontawesome.FontAwesomeIcon)`
  animation: ${swipe} 1s infinite ease-in-out;
  margin-left: 15px;
`;
var IconText = import_styled_components2.default.p`
  color: #fff;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
`;
var EditorWrapper = import_styled_components2.default.div``;
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Editor,
  EditorContent
});
