import { forwardRef } from 'react'
import styled from 'styled-components'
import { theme } from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

interface ICheckbox {
	id?: string
	name: string
	label: string
	desc?: string
	checked?: boolean
	className?: string
	disabled?: boolean
	onChange?: (e: any) => void
}

const Checkbox = forwardRef(
	({ id, name, label, className, desc, disabled = false, onChange, ...rest }: ICheckbox, ref) => {
		return (
			<Wrapper className={className}>
				<Container>
					<CheckboxContainer disabled={disabled}>
						<StyledInput
							type="checkbox"
							name={name}
							ref={ref}
							onChange={onChange}
							{...rest}
							disabled={disabled}
							id={id}
						/>
						<Mark>
							<StyledFontAwesomeIcon icon={faCheck} />
						</Mark>
					</CheckboxContainer>
					<Label>{label}</Label>
				</Container>
				{desc && <Desc>{desc}</Desc>}
			</Wrapper>
		)
	}
)

const Wrapper = styled.div``

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

const CheckboxContainer = styled.label<{ disabled: boolean }>`
	width: 13px;
	height: 13px;
	display: flex;
	justify-content: space-between;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	border: 1px solid ${theme`colors.mischka`};
	border-radius: 4px;
	overflow: hidden;
`

const Mark = styled.div`
	display: none;
`

const StyledInput = styled.input<{ ref: any; disabled: boolean }>`
	display: none;

	&:checked + ${Mark} {
		display: block;
		background-color: ${theme`colors.chambray`};
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;

		${({ disabled }) => disabled && 'opacity: 0.5'};
	}
`

const Label = styled.span`
	color: #374151;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
`

const Desc = styled.p`
	font-size: 14px;
	color: ${theme`colors.dustyGray`};
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
	font-size: 8px;
`

export default Checkbox
