import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Link } from 'react-router-dom'

export const StyledButton = styled(Link)`
	background-color: ${theme`colors.mercury`};
	color: ${theme`colors.mineShaft`};
	display: block;
	padding: 6px;
	border-radius: 6px;

	@media (min-width: ${theme`screens.lg`}) {
		align-self: center;
	}
`

export const HistoryButtonWrapper = styled.div`
	display: flex;
	align-self: flex-end;
	max-width: 461px;
	width: 100%;
	margin: auto;
	padding: 1rem 1rem 0;
	justify-content: space-between;

	@media (min-width: ${theme`screens.lg`}) {
		align-self: center;
		padding: 0;
		width: auto;
	}
`
