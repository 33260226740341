import { ReactNode } from 'react'
import styled from 'styled-components'

interface ICardWrapper {
	children: ReactNode
	className?: string
}

const CardWrapper = ({ children, className }: ICardWrapper) => {
	return <Wrapper className={className}>{children}</Wrapper>
}

const Wrapper = styled.div`
	background: #ffffff;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
	border-radius: 6px;
`

export default CardWrapper
