import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.625rem;
`

export const Dropzone = styled.div<{ ref: any }>`
	border: 1px dashed #d1d5db;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	min-height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	cursor: pointer;
`

export const ThumbsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 1rem;
`

export const Thumb = styled.div`
	display: inline-flex;
	width: 100px;
	height: 100px;
	padding: 4px;
	position: relative;
`

export const UploadFileText = styled.p`
	color: #0baccf;
	font-weight: 600;
	font-size: 14px;
`

export const UploadFileRestrictions = styled.p`
	color: #b9b9b9;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
`

export const StyledImage = styled.img`
	object-fit: contain;
	width: 100%;
`

export const Remove = styled.div`
	position: absolute;
	right: -2px;
	top: -2px;
	cursor: pointer;
	font-size: 12px;
	border-radius: 50%;
	background-color: #ddd;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		color: red;
	}
`

export const Edit = styled.div`
	position: absolute;
	left: -2px;
	top: -2px;
	cursor: pointer;
	font-size: 12px;
	border-radius: 50%;
	background-color: #ddd;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const ModalContent = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const RotateButtons = styled.div`
	display: flex;
	gap: 1rem;
`

export const Actions = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	padding: 1rem;
`
