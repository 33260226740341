import styled from 'styled-components'

export const StyledLabel = styled.label<{ disabled: boolean }>`
	font-size: 12px;
	color: ${({ disabled }) => (disabled ? '#676767' : '#676767')};
	font-weight: 700;
	opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

export const StyledIndicator = styled.span`
	color: red;
`
