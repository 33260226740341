import { ReactNode } from 'react'
import styled from 'styled-components'
import tw, { theme } from 'twin.macro'

import CardWrapper from './Wrapper'

type SummaryProps = {
	title: string
	content: string | ReactNode
	summaryCounter?: string | ReactNode
	summaryText?: string
	className?: string
	icon?: any
	iconBgColor?: string
	iconColor?: string
}

const Summary = ({
	title,
	content,
	summaryCounter,
	summaryText,
	className,
	icon,
	iconBgColor = theme`colors.zumthor` || '#fff',
	iconColor = theme`colors.accent` || '#000'
}: SummaryProps) => {
	return (
		<StyledCardWrapper className={className}>
			<div>
				<Title>{title}</Title>
				<Content>{content}</Content>
				<Text>
					<SummaryCounter>{summaryCounter}</SummaryCounter> <span>{summaryText}</span>
				</Text>
			</div>
			{icon && (
				<IconWrapper iconBgColor={iconBgColor} iconColor={iconColor}>
					{icon}
				</IconWrapper>
			)}
		</StyledCardWrapper>
	)
}

export const StyledCardWrapper = tw(CardWrapper)`py-3 px-4 flex gap-4 justify-between`

export const Title = styled.h3`
	color: ${theme`colors.dustyGray`};
	letter-spacing: -0.09em;
	font-size: 14px;
	line-height: 15px;
`

export const Content = styled.p`
	color: #505050;
	font-weight: 600;
	font-size: 32px;
	line-height: 44px;
`

const Text = tw.p`text-xs text-dustyGray`

const SummaryCounter = tw.span`text-malachite font-semibold`

export const IconWrapper = styled.div<{ iconBgColor: string; iconColor: string }>`
	background-color: ${({ iconBgColor }) => iconBgColor};
	border-radius: 50%;
	width: 40px;
	height: 40px;
	padding: 0.5rem;
	color: ${({ iconColor }) => iconColor};
	display: flex;
	align-items: center;
	justify-content: center;
`

export default Summary
