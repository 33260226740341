import { useState } from 'react'

import useOutsideClick from 'hooks/useOutsideClick'
import { Menu, MenuItems, MenuOption, Overlay } from './styles'
import { IEllipsisMenu } from './types'

const EllipsisMenu = ({ options, className, dataId }: IEllipsisMenu) => {
	const [isOpened, setIsOpened] = useState(false)
	const ref = useOutsideClick(() => setIsOpened(false))
	const toggleMenu = () => setIsOpened(!isOpened)

	return (
		<Menu onClick={toggleMenu} ref={ref} className={className} data-id={dataId}>
			{isOpened && (
				<>
					<Overlay />
					<MenuItems>
						{options.map((option) => (
							<MenuOption
								key={option.label}
								borderTop={option.borderTop}
								borderBottom={option.borderBottom}
								onClick={option.action}
								disabled={!!option.disabled}>
								{option.label}
							</MenuOption>
						))}
					</MenuItems>
				</>
			)}
		</Menu>
	)
}

export default EllipsisMenu
