import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Wrapper = styled.article`
	display: grid;
	font-size: 12px;
	text-align: center;
	border: 1px solid #e5e7eb;
	padding: 12px;
	gap: 0.5rem;
	font-weight: 600;
	color: #767676;
	background-color: ${theme`colors.white`};
	position: relative;

	& > span {
		text-overflow: ellipsis;
		overflow: hidden;
	}
`

export const BookingText = styled.span`
	font-size: 13px;
	font-weight: 600;
	color: #2c2e30;
`

export const Booth = styled.span`
	grid-row-start: 1;
	grid-column-start: 1;
	text-align: left;
	font-size: 13px;
	font-weight: 600;
	padding: 2px 4px;
	border-radius: 7px;
	grid-column-start: 2;
	background-color: #36b059;
	text-transform: uppercase;
	color: #fff;
	justify-self: end;
	font-size: 10px;
	align-self: start;
`

export const Dates = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	grid-row-start: 3;
	grid-column-start: 1;
	align-items: start;

	strong {
		color: #535353;
	}
`

export const DateText = styled.span`
	display: flex;
	width: 125px;
	justify-content: space-between;
	text-align: left;
`

export const ProductsRemaining = styled.span<{ expired: boolean }>`
	grid-row-start: 3;
	grid-column-start: 2;
	text-align: right;
	display: ${({ expired }) => (expired ? 'none' : 'inline')};
`

export const ProductsRemainingMobile = styled.span`
	color: #828282;
	display: block;
	font-size: 12px;
	font-weight: 600;
`

export const ProductsRemainingCount = styled.span`
	font-size: 18px;
	font-weight: 600;
	color: #454545;
`

export const ProductDaysRemainingMobile = styled.span<{ expired: boolean }>`
	text-align: left;
	display: ${({ expired }) => (expired ? 'none' : 'inline')};
`

export const Header = styled.div`
	grid-column-start: 1;
	grid-row-start: 1;
	text-align: left;
	display: flex;
	flex-direction: column;
`
