import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Editor } from 'editor'

import Header from 'components/Header'
import { Button } from 'components/Ui'
import EditorSidebar from 'components/EditorSidebar'

export const Wrapper = styled.div`
	display: flex;
	align-items: flex-start;
`

export const StyledHeader = styled(Header)`
	margin-bottom: 2rem;
`

export const StyledEditor = styled(Editor)<{ isSidebarOpen: boolean }>`
	justify-content: center;
	padding: 0 2rem;
	max-width: 100%;
	margin: auto;

	@media (min-width: ${theme`screens.lg`}) {
		justify-content: flex-start;
		padding: 0;
		margin: 0;
	}

	.editor-wrapper {
		max-width: 100%;
		max-height: calc(100vh - 59px - 2rem - 4rem);
		overflow: auto;

		@media (min-width: ${theme`screens.lg`}) {
			overflow: visible;
			transform: translateX(0);
		}
	}
`

export const StyledButton = styled(Button)`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 0;
	padding: 0.5rem;
	z-index: 1000;

	@media (min-width: ${theme`screens.md`}) {
		display: none;
	}
`

export const StyledEditorSidebar = styled(EditorSidebar)<{ isSidebarOpen: boolean }>`
	transform: ${({ isSidebarOpen }) => (isSidebarOpen ? 'translateX(0)' : 'translateX(-100vw)')};
	position: absolute;
	transition: transform 0.3s ease-in-out;
	top: 0;
	left: 0;
	right: 0;
	bottom: 40px;
	background: ${theme`colors.white`};
	z-index: 1000;
	margin: 0;
	width: 100%;
	overflow: auto;
	padding-bottom: 2rem;

	@media (min-width: ${theme`screens.lg`}) {
		display: block !important;
		transform: translateX(0);
		position: static;
		flex: none;
		width: 250px;
	}
`
