import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Link } from 'react-router-dom'

export const Wrapper = styled.article<{ isIceland: boolean }>`
	position: relative;
	display: grid;
	font-size: 12px;
	color: #989898;
	align-items: center;
	gap: 6px;
	border: 1px solid #e5e7eb;
	padding: 12px;
	grid-template-columns: 30px 2fr 1fr;
	background-color: ${theme`colors.white`};
`

export const Reference = styled(Link)`
	color: #3f85ef;
	font-weight: 700;
	grid-row-start: 1;
`

export const Created = styled.span`
	grid-column-start: 2;
`

export const Amount = styled.span`
	text-align: center;
	grid-row-start: 5;
	grid-row-end: 6;
	grid-column-start: 3;
	justify-self: end;
	color: #454545;
	font-weight: 600;
	font-size: 18px;
	text-align: right;
`

export const StatusWrapper = styled.span`
	text-align: center;
	grid-row-start: 1;
	grid-row-end: 1;
	grid-column-start: 3;
	justify-self: end;
	color: #454545;
	font-weight: 600;
	font-size: 18px;
	text-align: right;
`

export const Name = styled.span`
	grid-row-start: 1;
	grid-column-start: 2;
`

export const Email = styled.span`
	grid-column-start: 2;
`

export const SSN = styled.span`
	grid-column-start: 2;
`

export const BankInfo = styled.span`
	grid-column-start: 2;
`

export const Mobile = styled.span`
	font-weight: 700;
`
