import { useContext, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { NavContext } from 'store/NavContext'
import SearchInput from 'components/Ui/SearchInput'
import { useDevice } from 'hooks'

interface IHeader {
	title: string
	handleSearch?: any
	className?: string
}

const Header = ({ title, handleSearch, className }: IHeader) => {
	const ctx = useContext(NavContext)
	const [searchOpened, setSearchOpened] = useState(false)
	const { isMobileOrTabletHorizontal } = useDevice()

	const showSidebar = () => {
		ctx?.toggleNavOpened(true)
	}

	const closeSearch = () => setSearchOpened(false)
	const openSearch = () => setSearchOpened(true)

	return (
		<Wrapper searchOpened={searchOpened && isMobileOrTabletHorizontal} className={className}>
			{isMobileOrTabletHorizontal ? (
				!searchOpened ? (
					<>
						<HeaderMain>
							<Hambuger onClick={showSidebar}>
								<FontAwesomeIcon icon={faBars} />
							</Hambuger>
							<Title>{title}</Title>
						</HeaderMain>
						{handleSearch && (
							<SearchIconMobile onClick={openSearch}>
								<FontAwesomeIcon icon={faMagnifyingGlass} />
							</SearchIconMobile>
						)}
					</>
				) : (
					<SearchWrapper>
						<StyledSearchInput placeholder="Search" label="" onChange={handleSearch} />
						<StyledFontAwesomeIcon icon={faTimes} onClick={closeSearch} />
					</SearchWrapper>
				)
			) : (
				<>
					<HeaderMain>
						<Hambuger onClick={showSidebar}>
							<FontAwesomeIcon icon={faBars} />
						</Hambuger>
						<Title>{title}</Title>
					</HeaderMain>
				</>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div<{ searchOpened: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ searchOpened }) => (searchOpened ? '#fff' : '#171a35')};
	padding: 1rem;
	color: #fff;

	${({ searchOpened }) => searchOpened && 'box-shadow: 0 0 5px #ccc'};

	@media (min-width: ${theme`screens.lg`}) {
		background-color: transparent;
		padding: 0;
	}
`

const HeaderMain = styled.div`
	display: flex;
	gap: 10px;

	@media (min-width: ${theme`screens.lg`}) {
		color: #4d4d4d;
	}
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 18px;
	color: #fff;

	@media (min-width: ${theme`screens.lg`}) {
		color: #4d4d4d;
	}
`

const Hambuger = styled.button`
	@media (min-width: ${theme`screens.xl`}) {
		display: none;
	}
`

const SearchIconMobile = styled.button`
	height: 26px;
	width: 28px;
`

const StyledSearchInput = styled(SearchInput)`
	border: none;

	input {
		width: 100%;
		border: none;
		padding-top: 0;
		padding-bottom: 0;
		font-size: 1.125rem;
	}

	input:focus {
		box-shadow: none;
	}
`

const SearchWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	gap: 1rem;

	& > div {
		width: 100%;

		& > div {
			box-shadow: none;
			font-size: 2rem;
		}
	}

	svg {
		font-size: 1.25rem;
	}

	@media (min-width: ${theme`screens.lg`}) {
		display: none;
	}
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
	color: #535353;
	cursor: pointer;
`

export default Header
