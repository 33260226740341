import { useMediaQuery } from 'react-responsive'

export const useDevice = () => {
	const isMobileOrTablet = useMediaQuery({
		query: '(max-width: 767px)'
	})

	const isMobileOrTabletHorizontal = useMediaQuery({
		query: '(max-width: 1023px)'
	})

	return {
		isMobileOrTablet,
		isMobileOrTabletHorizontal
	}
}
