import styled from 'styled-components'
import { theme } from 'twin.macro'

import Form from 'components/Ui/Form'
import { FormContent } from 'components/Ui/Form'

export const Wrapper = styled.div`
	display: flex;
	column-gap: 2.125rem;
	align-items: flex-start;
	flex-direction: column;

	@media (min-width: ${theme`screens.md`}) {
		flex-direction: row;
	}
`

export const StyledForm = styled(Form)`
	width: 100%;

	${FormContent} {
		padding: 40px 30px 30px;
		border-radius: 10px;
		border-top: none;
	}
`

export const Row = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media (min-width: ${theme`screens.md`}) {
		flex-direction: row;

		& > * {
			flex: 1;
		}
	}
`
