import { ReactNode } from 'react'

import { Modal } from 'components/Ui'
import { customModalStyles } from 'styles/general'

interface ISortModal {
	children: ReactNode
	isOpened: boolean
	closeModal: () => void
}

const SortModal = ({ children, isOpened, closeModal }: ISortModal) => {
	return (
		<Modal
			isOpened={isOpened}
			closeModal={closeModal}
			title="Sort"
			customStyles={customModalStyles}>
			{children}
		</Modal>
	)
}

export default SortModal
