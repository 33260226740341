import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Security = styled.div`
	background-color: ${theme`colors.white`};
	border-radius: 10px;
	padding: 30px;
	margin: 0 auto;
	width: 100%;
	max-width: 461px;

	@media (min-width: ${theme`screens.md`}) {
		max-width: 500px;
		margin: 20px auto 0;
	}
`

export const Button = styled(Link)`
	background-color: ${theme`colors.scorpion`};
	color: ${theme`colors.white`};
	width: 100%;
	display: block;
	text-align: center;
	padding: 12px;
	font-weight: 600;
	border-radius: 8px;
`

export const SecurityTitle = styled.h3`
	font-size: 1.125rem;
	margin-bottom: 10px;
	font-weight: 600;
	color: ${theme`colors.emperor`};
`

export const Buttons = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
`
