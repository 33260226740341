import { forwardRef } from 'react'

import Label from 'components/Ui/Label'
import { Group, FieldWrapper, IconWrapper, Icon, StyledInput } from './styles'
import FormError from 'components/Ui/FormError'

import type { Ref } from 'react'
import type { Input as InputType } from './types'

const Input = forwardRef(
	(
		{
			id,
			label,
			name,
			icon,
			placeholder,
			onChange,
			disabled,
			className,
			type = 'text',
			error,
			step,
			required = false
		}: InputType,
		ref: Ref<HTMLInputElement>
	) => {
		return (
			<Group className={className}>
				{label && (
					<Label htmlFor={id} required={required}>
						{label}
					</Label>
				)}
				<FieldWrapper>
					{icon && (
						<>
							{typeof icon === 'string' ? (
								<IconWrapper>{icon}</IconWrapper>
							) : (
								<IconWrapper>
									<Icon icon={icon} />
								</IconWrapper>
							)}
						</>
					)}
					<StyledInput
						type={type}
						id={id}
						name={name}
						placeholder={placeholder}
						onChange={onChange}
						ref={ref}
						withIcon={icon}
						disabled={disabled}
						step={step}
					/>
				</FieldWrapper>
				{error && <FormError>{error.message}</FormError>}
			</Group>
		)
	}
)

export default Input
