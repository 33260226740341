import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6px;
	width: 100%;
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const Switcher = styled.label`
	width: 42px;
	display: inline-block;
	display: flex;
	justify-content: space-between;
	height: 22px;
	cursor: pointer;
`

export const Slider = styled.div`
	background-color: #ccc;
	width: 100%;
	height: 100%;
	border: 1px solid #ccc;
	border-radius: 22px;

	&:before {
		content: '';
		display: block;
		border-radius: 50%;
		background-color: #fff;
		width: 20px;
		height: 20px;
		top: 1px;
		left: 1px;
		transition: 0.3s ease-in-out;
	}
`

export const StyledInput = styled.input<{ ref: any }>`
	display: none;

	&:checked + ${Slider} {
		border-color: #21a34d;
		background-color: #21a34d;

		&:before {
			transform: translateX(20px);
		}
	}

	&:disabled + ${Slider} {
		background-color: #ccc;
		border-color: #ccc;
		cursor: not-allowed;

		&:before {
			transform: translateX(0);
		}
	}
`

export const Label = styled.span`
	color: #374151;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
`

export const Description = styled.p`
	color: ${theme`colors.dustyGray`};
	font-size: ${theme`fontSize.sm`};
`
