import styled, { keyframes } from 'styled-components'

const Loader = () => {
	return (
		<LoaderWrapper>
			<Spinner />
		</LoaderWrapper>
	)
}

export const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	height: 50%;
	align-items: center;
`

export const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }

`

export const Spinner = styled.div`
	border: 0.2em solid rgba(0, 0, 0, 0.1);
	border-top: 0.2em solid #767676;
	border-radius: 50%;
	width: 2.28571429em;
	height: 2.28571429em;
	animation: ${spin} 0.6s linear infinite;
`

export default Loader
