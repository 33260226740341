import styled from 'styled-components'
import { theme } from 'twin.macro'

import Table from 'components/Ui/Table'
import SearchInput from 'components/Ui/SearchInput'
import Summary, { Title as SummaryTitle, Content as SummaryContent } from 'components/Card/Summary'
import Select from 'components/Ui/Select'
import SortSelect from 'components/Ui/Select/Sort'
import Table2 from 'components/Ui/Table2'
import {
	Header as TableHeader,
	List as TableList,
	Container as TableContainer
} from 'components/Ui/Table'

export const StyledTable = styled(Table)`
	${TableHeader} {
		grid-template-columns: 13px 3fr repeat(2, 1fr) 2fr repeat(7, 1fr);

		@media (min-width: ${theme`screens.md`}) {
			width: 2000px;
		}

		& > *:nth-child(1) {
			text-align: left;
		}
	}

	${TableContainer} {
		overflow-x: auto;
		margin-bottom: 35px;
	}

	${TableList} {
		margin-bottom: 0;

		@media (min-width: ${theme`screens.md`}) {
			width: 2000px;
		}
	}
`

export const FiltersContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: flex-end;

	@media (min-width: ${theme`screens.md`}) {
		gap: 20px;
	}
`

export const StyledSearchInput = styled(SearchInput)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const CategorySelect = styled(Select)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const StyledBoothSelect = styled(Select)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const StyledUsersSelect = styled(Select)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const FiltersModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`

export const StyledSummary = styled(Summary)`
	padding: 10px 14px;

	@media (min-width: ${theme`screens.sm`}) {
		padding: 10px 24px;
	}

	${SummaryTitle} {
		font-size: 0.875rem;
	}

	${SummaryContent} {
		font-size: 1rem;
		line-height: normal;
		margin-top: 4px;
	}
`

export const Summaries = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 12px 24px;
	gap: 0.25rem;
	flex: 1;

	@media (min-width: ${theme`screens.sm`}) {
		grid-template-columns: repeat(3, 1fr);
		width: 100%;
		gap: 1.125rem;
	}

	@media (min-width: ${theme`screens.xl`}) {
		margin: 10px 0;
		display: inline-flex;
		flex-wrap: wrap;
		padding: 0;
	}
`

export const Content = styled.div`
	display: flex;
	flex-direction: column-reverse;

	@media (min-width: ${theme`screens.md`}) {
		flex-direction: column;
	}

	@media (min-width: ${theme`screens.lg`}) {
		align-items: flex-start;
	}
`

export const FiltersRow = styled.div`
	display: flex;
	gap: 20px;
`

export const DesktopSortSelect = styled(SortSelect)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: block;
	}
`

export const StyledTable2 = styled(Table2)`
	height: 65vh;

	@media (min-width: ${theme`screens.md`}) {
		height: calc(100vh - 59px - 164px - 59px - 12px - 90px);
	}

	@media (min-width: ${theme`screens.lg`}) {
		height: calc(100vh - 2rem - 27px - 164px - 90px - 120px);
	}

	@media (min-width: 1667px) {
		height: calc(100vh - 2rem - 27px - 171px - 90px - 30px);
	}
`

export const BookingCell = styled.div`
	display: flex;
	flex-direction: column;
`

export const BookingReference = styled.div`
	font-size: 0.75rem;
`

export const CommissionPercentage = styled.span`
	font-size: 0.75rem;
`

export const ProductWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1.125rem;
`

export const ProductWrapperName = styled.div`
	display: flex;
	flex-direction: column;
`

export const UserNameCell = styled.div`
	display: flex;
	flex-direction: column;
`

export const UserEmail = styled.span`
	font-size: 0.75rem;
`

export const Sku = styled.span`
	font-size: 0.75rem;
`
