import styled from 'styled-components'
import { theme } from 'twin.macro'

import Switch from 'components/Ui/Switch'

export const Wrapper = styled.div`
	padding: 0 1rem;

	@media (min-width: ${theme`screens.lg`}) {
		padding: 0;
	}
`

export const TagsDesc = styled.p`
	color: #989898;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: right;
`

export const StyledSwitch = styled(Switch)`
	align-self: flex-end;
	display: flex;
`

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 18px;

	& > * {
		flex: 1;
	}
`
