import { ReactNode } from 'react'

import { Error } from './styles'

type FormErrorProps = {
	children: ReactNode
}

const FormError = ({ children }: FormErrorProps) => {
	return <Error>{children}</Error>
}

export default FormError
