import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Wrapper = styled.article`
	position: relative;
	display: grid;
	font-size: 12px;
	font-weight: 600;
	color: #989898;
	padding: 12px;
	background-color: ${theme`colors.white`};
	border: 1px solid ${theme`colors.athensGray`};
	box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
	text-align: left;
`

export const Mobile = styled.span`
	font-weight: 700;
`
