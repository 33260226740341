import { ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from 'twin.macro'

import { Modal } from 'components/Ui'
import { Button } from 'components/Ui'
import { t } from 'i18next'
import Select from 'components/Ui/Select'
import { defaultConfig } from 'config/default'
import { IOption } from 'interfaces/select.interface'

interface IConfirmModal {
	isOpened: boolean
	disableButton: boolean
	children?: ReactNode
	isLoading?: boolean
	closeModal: () => void
	confirm: () => void
	onChange: (data: IOption) => void
}

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.5)',
		zIndex: '100'
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '350px',
		backgroundColor: theme`colors.white`,
		width: '100%',
		padding: '0',
		border: 'none',
		overflow: 'unset'
	}
}

const FormatModal = ({
	isOpened,
	closeModal,
	confirm,
	isLoading,
	disableButton,
	onChange
}: IConfirmModal) => {
	return (
		<Modal
			isOpened={isOpened}
			closeModal={closeModal}
			title={t('formatModal.title')}
			customStyles={customStyles}>
			<Content>
				<Select
					id="exportFormat"
					label={t('formatModal.select.label')}
					options={defaultConfig.exportFormatOptions}
					onChange={onChange}
				/>
			</Content>
			<Buttons>
				<Button
					text={t('formatModal.confirm')}
					styleType="primary"
					onClick={confirm}
					disabled={disableButton}
					isLoading={isLoading}
				/>
			</Buttons>
		</Modal>
	)
}

const Buttons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	background-color: ${theme`colors.white`};
	padding: 1rem;
`

const Content = styled.div`
	padding: 0 1rem 1rem;
	font-size: 0.875rem;
	color: ${theme`colors.scorpion`};
`

export default FormatModal
