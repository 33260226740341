import Pagination from 'components/Pagination'
import Spinner from 'components/Ui/Spinner'
import { Wrapper, Container, Header, Title, List, Footer } from './styles'
import { ITable } from './types'

const Table = ({
	children,
	headers,
	className,
	hasCheckbox = false,
	isLoading,
	prev,
	next,
	handleCheckbox,
	onClick
}: ITable) => {
	if (isLoading) return <Spinner />

	return (
		<Wrapper className={className}>
			<Container>
				<Header>
					{hasCheckbox && <input type="checkbox" onChange={handleCheckbox} />}
					{headers.map((header: any) => (
						<Title key={header.id}>{header.label}</Title>
					))}
				</Header>
				<List>{children}</List>
			</Container>
			{prev || next ? (
				<Footer>
					{prev || next ? (
						<Pagination
							onClick={onClick ? onClick : () => {}}
							prev={prev ?? null}
							next={next ?? null}
						/>
					) : null}
				</Footer>
			) : null}
		</Wrapper>
	)
}

export default Table
