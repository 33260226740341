import { AsyncPaginate } from 'react-select-async-paginate'
import { useAxiosPrivate } from 'hooks'
import { forwardRef } from 'react'

import Label from 'components/Ui/Label'
import { theme } from 'twin.macro'
import FormError from 'components/Ui/FormError'

import { Group } from './styles'

export type OptionType = {
	value: number
	label: string
}

function getColor(state: any): string {
	if (state.isDisabled) {
		return '#9b9b9b'
	}
	return state.isSelected || state.isFocused ? '#fff' : '#676767'
}

function getBackgroundColor(state: any): string {
	if (state.isDisabled) {
		return '#fff'
	}
	return state.isSelected || state.isFocused ? '#465CFF' : '#fff'
}

const customStyles = {
	option: (provided: any, state: any) => ({
		...provided,
		color: getColor(state),
		backgroundColor: getBackgroundColor(state),
		cursor: 'pointer',
		whiteSpace: 'nowrap'
	}),
	menu: (base: any) => ({
		...base,
		zIndex: '10000',
		minWidth: '100%',
		right: '0',
		[`@media (min-width: ${theme`screens.md`})`]: {
			...base[`@media only screen and (min-width: ${theme`screens.md`})`],
			width: 'auto'
		}
	}),
	control: (_base: any, state: any) => {
		return {
			display: 'flex',
			boxShadow: state.isFocused ? '0 0 5px #465cff' : '0px 0px 4px rgba(0, 0, 0, 0.08)',
			borderRadius: '8px',
			backgroundColor: theme`colors.white`
		}
	}
}

const AsyncSelect = forwardRef(({ required, ...props }: any, ref) => {
	const axiosPrivate = useAxiosPrivate()

	const loadOptions = async (search: string, _loadedOptions: any, additional: any) => {
		let filteredOptions: OptionType[]
		let hasMore = false
		let cursor = additional?.cursor ?? ''
		let response = null

		if (!search) {
			response = await axiosPrivate.get(`/accounts/${cursor ? `?cursor=${cursor}` : ''}`)

			filteredOptions = response?.data?.results?.map((user: any) => ({
				label: `${user.email}`,
				value: user.id
			}))

			hasMore = !!response?.data?.next
			cursor = response?.data?.next.split('cursor=')[1]
		} else {
			const searchLower = search.toLowerCase()
			response = await axiosPrivate.get(`/accounts/${searchLower ? `?search=${searchLower}` : ''}`)

			cursor = ''
			hasMore = false
		}

		filteredOptions = response?.data?.results?.map((user: any) => ({
			label: `${user.email}${user.name ? ` - ${user.name}` : ''} `,
			value: user.id
		}))

		return {
			options: filteredOptions,
			hasMore,
			additional: {
				cursor
			}
		}
	}

	return (
		<Group ref={ref} disabled={props.disabled} index="0" className={props.className}>
			<Label disabled={props.disabled} required={required}>
				{props.label}
			</Label>
			<AsyncPaginate
				loadOptions={loadOptions}
				onChange={props.onChange}
				{...props}
				refs={ref}
				name={props.name}
				styles={customStyles}
				isClearable={true}
				isDisabled={props.disabled}
				components={{ IndicatorSeparator: () => null }}
			/>
			{props.error && <FormError>{props.error.message}</FormError>}
		</Group>
	)
})

export default AsyncSelect
