import { useNavigate } from 'react-router-dom'

export const usePagination = (setQuery: any) => {
	const navigate = useNavigate()

	const handlePagination = (data: string) => {
		const query = data.split('?')[1]

		setQuery(query)
		navigate({ search: query })
	}

	return {
		handlePagination
	}
}
