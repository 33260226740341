import styled from 'styled-components'
import { theme } from 'twin.macro'

import CardWrapper from '../Wrapper'

export const StyledCardWrapper = styled(CardWrapper)`
	font-size: 0.875rem;
	color: ${theme`colors.riverBed`};
	font-weight: 600;
`

export const Header = styled.header`
	display: flex;
	justify-content: space-between;
	font-weight: 600;
`

export const Title = styled.h3`
	color: ${theme`colors.paleSky`};
	font-size: 1.125rem;
`

export const Booth = styled.span`
	color: ${theme`colors.riverBed`};
	font-size: 1.875rem;
`

export const Legend = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-top: 1rem;
`

export const LegendItem = styled.span`
	display: flex;
	gap: 0.5rem;
	align-items: center;
`
