import { useRowSelect, SelectTypes } from '@table-library/react-table-library/select'

export const useTableSelect = (data: any, onSelectChange: (action: any, state: any) => void) => {
	const select = useRowSelect(
		data,
		{
			onChange: onSelectChange
		},
		{
			isCarryForward: false,
			rowSelect: SelectTypes.MultiSelect
		}
	)

	return { select }
}
