import styled from 'styled-components'
import { theme } from 'twin.macro'

import Select from 'components/Ui/Select'

export const Wrapper = styled.div`
	padding: 0 1rem;
	@media (min-width: ${theme`screens.lg`}) {
		padding: 0;
	}
`

export const Row = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media (min-width: ${theme`screens.md`}) {
		justify-content: space-between;
		flex-direction: row;
		& > * {
			flex: 1;
		}
	}
`

export const StyledSelect = styled(Select)`
	label {
		opacity: 1;
	}
`
