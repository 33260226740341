import { useSearchParams, useNavigate } from 'react-router-dom'

export const useFilter = (setQuery: (data: string) => void) => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const handleFilter = ({ id, value }: { id: string; value: string | null }) => {
		if (value) {
			searchParams.set(id, value)
		} else {
			searchParams.delete(id)
		}

		searchParams.delete('cursor')
		setQuery(searchParams.toString())
		navigate({ search: searchParams.toString() })
	}

	return { handleFilter }
}
