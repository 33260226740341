import styled from 'styled-components'
import { theme } from 'twin.macro'

import { Button } from 'components/Ui'

export const StyledForm = styled.form`
	margin: 30px auto;
	max-width: 461px;
	display: flex;
	flex-direction: column;
	gap: 14px;
	width: 100%;

	@media (min-width: ${theme`screens.lg`}) {
		margin: 20px 0 0;
	}
`

export const StyledButton = styled(Button)`
	border-radius: 8px;
	padding: 10px;
`

export const FormContent = styled.div<{ hideBorderTop?: boolean }>`
	background: #ffffff;

	padding: 10px 14px 20px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	border-top: ${({ hideBorderTop }) =>
		hideBorderTop ? 'none' : `3px solid ${theme`colors.malibu`}`};

	${({ hideBorderTop }) =>
		hideBorderTop
			? `
				border: 1px solid ${theme`colors.seashell`};
				border-top: none;
				`
			: `
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
	`}
`
