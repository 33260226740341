import { forwardRef, useContext } from 'react'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons'

import Input from 'components/Ui/Input'
import { StoreSettingsContext } from 'store/StoreSettingsContext'

import type { Ref } from 'react'
import type { IPriceInput } from './types'

const PriceInput = forwardRef(
	(
		{ id, label, name, error, type, disabled, required, ...rest }: IPriceInput,
		ref: Ref<HTMLInputElement>
	) => {
		const storeCtx = useContext(StoreSettingsContext)
		const currency = storeCtx?.storeSettings?.currency
		let currencyIcon: any = faEuroSign

		if (currency === 'ISK' || currency === 'DKK') {
			currencyIcon = 'kr.'
		}

		return (
			<Input
				id={id}
				label={label}
				name={name}
				icon={currencyIcon}
				error={error}
				ref={ref}
				type={type}
				disabled={disabled}
				required={required}
				{...rest}
			/>
		)
	}
)

export default PriceInput
