import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { useContextMenu } from 'use-context-menu'

import { IEllipsisMenu } from './types'

import { Wrapper, StyledContextMenuItem } from './styles'

const EllipsisMenu = ({ options, className, dataId }: IEllipsisMenu) => {
	const {
		contextMenu: menu,
		onContextMenu: onClick,
		onKeyDown
	} = useContextMenu(
		<>
			{options.map((option: any) => (
				<StyledContextMenuItem key={option.label} onSelect={() => option.action(dataId)}>
					{option.label}
				</StyledContextMenuItem>
			))}
		</>,
		{ alignTo: 'auto-target' }
	)

	return (
		<>
			<Wrapper className={className} onClick={onClick} onKeyDown={onKeyDown} tabIndex={0}>
				<FontAwesomeIcon icon={faEllipsisVertical} />
			</Wrapper>
			{menu}
		</>
	)
}

export default EllipsisMenu
