import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Styles } from 'react-modal'
import { MonthlyCalendar } from '@zach.codes/react-calendar'

export const TableWrapper = styled.div`
	padding: 12px 24px;

	@media (min-width: ${theme`screens.lg`}) {
		padding: 12px 0;
	}
`

export const customModalStyles: Styles = {
	overlay: {
		zIndex: '1000',
		backgroundColor: 'rgba(0,0,0,0.5)'
	}
}

export const customTableStyles = {
	Table: `
		border: 1px solid ${theme`colors.iron`};
		border-radius: 8px;
		align-content: start;
		height: auto;
		max-height: 100%;

		th {
			border-bottom: 1px solid ${theme`colors.iron`} !important;
			background-color: ${theme`colors.athensGray2`};
			font-weight: 600;

			&:first-of-type {
				z-index: 5;
			}
		}

		tr:nth-of-type(even) {
			background-color: ${theme`colors.alabaster2`};
		}

		& .row-select-selected,
		& .row-select-single-selected {
			background-color: ${theme`colors.iron`} !important;
		}

	`,
	BaseCell: `
			font-size: 0.875rem;
			font-weight: 600;
			color: ${theme`colors.boulder2`};
			border: none !important;
			padding: 10px;

			&:nth-of-type(1) {
				left: 0px;
				position: sticky;
				z-index: 3;
			}

			&:nth-of-type(2) {
				left: 40px;
			}

			& > div:last-of-type {
				overflow: visible;
			}
		`
}

export const StyledCalendar = styled(MonthlyCalendar)`
	.rc-divide-y > :not([hidden]) ~ :not([hidden]) {
		--tw-divide-y-reverse: 0;
		border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
		border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
	}

	.rc-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
		--tw-divide-opacity: 1;
		border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
		border: none;
		display: none;
	}

	.rc-bg-white {
		--tw-bg-opacity: 1;
		background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
	}

	.rc-border-gray-300 {
		--tw-border-opacity: 1;
		border-color: rgba(209, 213, 219, var(--tw-border-opacity));
	}

	.rc-border-indigo-600 {
		--tw-border-opacity: 1;
		border-color: rgba(79, 70, 229, var(--tw-border-opacity));
	}

	.hover\:rc-border-gray-500:hover {
		--tw-border-opacity: 1;
		border-color: rgba(107, 114, 128, var(--tw-border-opacity));
	}

	.rc-rounded-lg {
		border-radius: 0.5rem;
	}

	.rc-border {
		border-width: 1px;
	}

	.rc-border-t-2 {
		border-top-width: 2px;
	}

	.rc-border-r-2 {
		border-right-width: 2px;
	}

	.rc-border-b-2 {
		border-bottom-width: 2px;
	}

	.rc-border-l-2 {
		border-left-width: 2px;
	}

	.rc-cursor-pointer {
		cursor: pointer;
	}

	.rc-block {
		display: block;
	}

	.rc-flex {
		display: flex;
	}

	.rc-grid {
		display: grid;
	}

	.rc-hidden {
		display: none;
	}

	.rc-justify-end {
		justify-content: flex-end;
	}

	.rc-justify-between {
		justify-content: space-between;
	}

	.rc-flex-1 {
		flex: 1 1 0%;
	}

	.rc-font-medium {
		font-weight: 500;
	}

	.rc-font-bold {
		font-weight: 700;
	}

	.rc-h-48 {
		height: 12rem;
	}

	.rc-text-sm {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}

	.rc-mr-4 {
		margin-right: 1rem;
	}

	.rc-mb-4 {
		margin-bottom: 1rem;
	}

	.rc-ml-4 {
		margin-left: 1rem;
	}

	.rc-max-h-36 {
		max-height: 9rem;
	}

	.rc-max-h-96 {
		max-height: 24rem;
	}

	.rc-overflow-auto {
		overflow: auto;
	}

	.rc-overflow-hidden {
		overflow: hidden;
	}

	.rc-overflow-y-auto {
		overflow-y: auto;
	}

	.rc-p-2 {
		padding: 0.5rem;
	}

	.rc-p-4 {
		padding: 1rem;
	}

	.rc-py-2 {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.rc-py-4 {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	* {
		--tw-shadow: 0 0 #0000;
	}

	* {
		--tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
		--tw-ring-offset-width: 0px;
		--tw-ring-offset-color: #fff;
		--tw-ring-color: rgba(59, 130, 246, 0.5);
		--tw-ring-offset-shadow: 0 0 #0000;
		--tw-ring-shadow: 0 0 #0000;
	}

	.rc-text-center {
		text-align: center;
	}

	.rc-text-gray-500 {
		--tw-text-opacity: 1;
		color: rgba(107, 114, 128, var(--tw-text-opacity));
	}

	.rc-text-gray-800 {
		--tw-text-opacity: 1;
		color: rgba(31, 41, 55, var(--tw-text-opacity));
	}

	.rc-w-32 {
		width: 8rem;
	}

	.rc-w-72 {
		width: 18rem;
	}

	.rc-w-full {
		width: 100%;
	}

	.rc-gap-2 {
		gap: 0.5rem;
	}

	.rc-grid-cols-1 {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}

	.rc-grid-cols-2 {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media (min-width: 640px) {
		.sm\:rc-w-36 {
			width: 9rem;
		}

		.sm\:rc-grid-cols-2 {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}

	@media (min-width: 768px) {
		.md\:rc-flex {
			display: flex;
		}

		.md\:rc-justify-between {
			justify-content: space-between;
		}

		.md\:rc-w-3\/4 {
			width: 75%;
		}

		.md\:rc-grid-cols-1 {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}

		.md\:rc-grid-cols-4 {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	@media (min-width: 1024px) {
		.lg\:rc-block {
			display: block;
		}

		.lg\:rc-hidden {
			display: none;
		}

		.lg\:rc-w-1\/2 {
			width: 50%;
		}

		.lg\:rc-grid-cols-3 {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		.lg\:rc-grid-cols-4 {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		.lg\:rc-grid-cols-5 {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}

		.lg\:rc-grid-cols-6 {
			grid-template-columns: repeat(6, minmax(0, 1fr));
		}

		.lg\:rc-grid-cols-7 {
			grid-template-columns: repeat(7, minmax(0, 1fr));
		}
	}
`

export const FiltersContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: flex-end;
	flex-wrap: wrap;
`
