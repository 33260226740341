import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Wrapper = styled.div`
	padding: 0;
`

export const Container = styled.div``

export const List = styled.div`
	margin-bottom: 35px;
	display: flex;
	flex-direction: column;

	@media (min-width: ${theme`screens.md`}) {
		row-gap: 1px;
		background: #e1e1e1;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
	}
`

export const Header = styled.div`
	display: none;
	gap: 1rem;
	text-align: center;
	align-items: center;
	padding: 10px 28px 10px 7px;
	background-color: #f2f2f2;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);

	@media (min-width: ${theme`screens.md`}) {
		display: grid;
	}

	& > *:nth-child(2) {
		text-align: left;
	}
`

export const Title = styled.span`
	font-weight: 600;
	font-size: 12px;
	color: #595959;
`

export const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: ${theme`screens.md`}) {
		justify-content: flex-end;
	}
`
