import { useEffect, useContext } from 'react'

import axios from 'axios'
import useRefreshToken from 'hooks/useRefreshToken'
import { AuthContext } from 'store/AuthContext'

export const useAxiosPrivate = () => {
	const refresh = useRefreshToken()
	const { auth, setAuth } = useContext(AuthContext)

	useEffect(() => {
		const requestIntercept = axios.interceptors.request.use(
			(config) => {
				if (!config.headers!['Authorization'] && auth?.accessToken != null) {
					config.headers!['Authorization'] = `Bearer ${auth?.accessToken}`
				}
				return config
			},
			(error) => Promise.reject(error)
		)

		const responseIntercept = axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				const prevRequest = error?.config

				if (error?.response?.status === 403 && !prevRequest?.sent) {
					prevRequest.sent = true
					const newAccessToken = await refresh()
					setAuth((prev: any) => ({ ...prev, accessToken: newAccessToken }))

					prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`

					return axios(prevRequest)
				}

				return Promise.reject(error)
			}
		)

		return () => {
			axios.interceptors.request.eject(requestIntercept)
			axios.interceptors.response.eject(responseIntercept)
		}
	}, [auth, refresh])

	return axios
}
