import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'

import Spinner from 'components/Ui/Spinner'
import Pagination from 'components/Pagination'
import { ITable } from './types'
import { Wrapper, Footer } from './styles'

const Table2 = ({
	columns,
	data,
	isLoading,
	select,
	prev,
	next,
	customStyles = {},
	className,
	onClick
}: ITable) => {
	if (isLoading) return <Spinner />

	const theme = useTheme([getTheme(), customStyles])

	return (
		<>
			<Wrapper className={className}>
				<CompactTable
					columns={columns}
					data={data}
					theme={theme}
					layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}
					select={select}
				/>
			</Wrapper>
			{prev || next ? (
				<Footer>
					{prev || next ? (
						<Pagination
							onClick={onClick ? onClick : () => {}}
							prev={prev ?? null}
							next={next ?? null}
						/>
					) : null}
				</Footer>
			) : null}
		</>
	)
}

export default Table2
