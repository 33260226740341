import styled from 'styled-components'

export const Group = styled.div<{ ref: any; disabled: boolean; index: string }>`
	font-size: 12px;
	min-width: 130px;
	cursor: pointer;
	row-gap: 10px;
	display: flex;
	flex-direction: column;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
