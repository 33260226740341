import { useContext } from 'react'

import { DashboardContext } from 'store'
import { BoothsList, Booth } from '../styles'

export const DashboardBoothsList = () => {
	const dashboardCtx = useContext(DashboardContext)

	return (
		<BoothsList>
			{dashboardCtx?.dashboard?.data?.bookings?.length || 0 > 0
				? Array.isArray(dashboardCtx?.dashboard?.data?.bookings) &&
				  dashboardCtx?.dashboard?.data?.bookings.length &&
				  dashboardCtx?.dashboard?.data?.bookings
						.filter((booth: any) => new Date() <= new Date(booth.end_date))
						.slice(0, 4)
						.map((booth: any, index: number) => <Booth key={index} {...booth} type="range" />)
				: ''}
		</BoothsList>
	)
}
