import { createContext, ReactNode, useState } from 'react'

interface IAuthContextProvider {
	children: ReactNode
}

interface IAuthContext {
	auth: any
	setAuth: (auth: any) => void
}

const initAuthContext = {
	auth: {
		user: null
	},
	setAuth: () => {}
}

export const AuthContext = createContext<IAuthContext>(initAuthContext)

export const AuthContextProvider = ({ children }: IAuthContextProvider) => {
	const [auth, setAuth] = useState({})

	const value = {
		auth,
		setAuth
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
