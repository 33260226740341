import styled from 'styled-components'
import { theme } from 'twin.macro'

import { BackButton } from './ButtonBack'

export const MobileBackButton = styled(BackButton)`
	@media (min-width: ${theme`screens.lg`}) {
		display: none;
	}
`
