import { Navigate, Outlet } from 'react-router-dom'

import { useUserRoles } from 'hooks'

const PreventUser = () => {
	const { userRole } = useUserRoles()

	return userRole === 'admin' || userRole === 'superuser' || userRole === 'staff' ? (
		<Outlet />
	) : (
		<Navigate to="/" />
	)
}

export default PreventUser
