import { theme } from 'twin.macro'
import styled from 'styled-components'

import Header from 'components/Header'
import Select from 'components/Ui/Select'

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 10px;
	flex-direction: column;

	@media (min-width: ${theme`screens.sm`}) {
		flex-direction: row;
	}

	& > * {
		flex: 1;
	}
`

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;

	@media (min-width: ${theme`screens.lg`}) {
		flex-direction: row;
		max-width: 461px;
		justify-content: space-between;
		align-items: center;
	}
`

export const StyledSelect = styled(Select)`
	label {
		opacity: 1;
	}
`

export const StyledHeader = styled(Header)`
	width: 100%;
`
