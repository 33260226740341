import styled from 'styled-components'

import { Button } from 'components/Ui'
import { Link } from 'react-router-dom'

export const StyledSection = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 1rem;
`

export const StyledForm = styled.form`
	max-width: 362px;
	box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.12);
	border-radius: 10px;
	padding: 30px 24px;
	width: 100%;
`

export const StyledButton = styled(Button)`
	background-color: #525252;
	display: block;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 10px;
	color: #fff;
	font-weight: 900;
	margin-top: 30px;
`

export const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export const StyledImage = styled.img`
	display: block;
	margin: 0 auto 20px;
	max-height: 100px;
`

export const Title = styled.h1`
	font-size: 1rem;
	color: #5e5e5e;
	font-weight: 600;
`

export const Header = styled.div`
	margin-bottom: 25px;
`

export const LanguageSwitcherContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	font-weight: bold;
	font-size: 0.875rem;
	color: #676767;
`

export const Subtitle = styled(Link)`
	font-size: 0.75rem;
	font-weight: 600;
`
