import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Wrapper = styled.div`
	padding: 0 1rem;

	@media (min-width: ${theme`screens.lg`}) {
		padding: 0;
	}
`
