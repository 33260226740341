import { theme } from 'twin.macro'
import styled from 'styled-components'

interface IAdvancedStatus {
	status: string
}

const AdvancedStatus = ({ status }: IAdvancedStatus) => {
	let color = theme`colors.malachite`

	switch (status) {
		case 'init':
		case 'initial':
			color = theme`colors.royalBlue`
			break
		case 'requested':
			color = theme`colors.scooter`
			break
		case 'pending':
		case 'processing':
			color = theme`colors.copper`
			break
		case 'canceled':
		case 'cancelled':
			color = theme`colors.milanoRed`
			break
		case 'error':
			color = theme`colors.martix`
			break
		case 'undefined':
			color = theme`colors.emperor`
			break
	}

	return (
		<PaymentWrapper>
			<PaymentStatusIndicator color={color} />
			<span>{status}</span>
		</PaymentWrapper>
	)
}

const PaymentWrapper = styled.div`
	
		padding: 2px 4px;
		border-radius: 7px;
		background-color: #36b059;
		text-transform: uppercase;
		color: #fff;
		justify-self: end;
		font-size: 10px;
		grid-row-start: 1;
		grid-column-start: 3;


	grid-column-start: auto;
	border-radius: 0.375rem;
	padding: 0.25rem 0.5rem;
	gap: 0.25rem;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	justify-self: center;
	border: 1px solid #7c7c7c;
	background-color: transparent;
	color: #656565;
	grid-row-start: auto;

	}
`

const PaymentStatusIndicator = styled.div<{ color: string }>`
	// display: none;

	display: block;
	width: 8px;
	height: 8px;
	background-color: ${({ color }) => color};
`

export default AdvancedStatus
