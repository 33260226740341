import { useContext } from 'react'

import { AuthContext } from 'store/AuthContext'

export const useUserRoles = () => {
	const { auth } = useContext(AuthContext)

	const userRole = auth?.user?.scopes[auth?.user?.scopes.length - 1] || 'customer'

	return {
		userRole,
		isUser: userRole === 'customer',
		isAdmin: auth?.user?.scopes?.includes('admin'),
		isSuperuser: auth?.user?.scopes?.includes('superuser'),
		isStaff: auth?.user?.scopes?.includes('staff')
	}
}
