import styled from 'styled-components'
import { theme } from 'twin.macro'

export const StatusWrapper = styled.div<{ status: boolean }>`
	padding: 2px 4px;
	border-radius: 7px;
	grid-column-start: 2;
	background-color: ${({ status }) => (status ? '#36B059' : '#363636')};
	text-transform: uppercase;
	color: #fff;
	justify-self: end;
	font-size: 10px;

	@media (min-width: ${theme`screens.md`}) {
		grid-column-start: auto;
		border-radius: 0.375rem;
		padding: 0.25rem 0.5rem;
		gap: 0.25rem;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		justify-self: center;
		border: 1px solid #7c7c7c;
		background-color: transparent;
		color: #656565;
	}
`

export const StatusIndicator = styled.div<{ status: boolean }>`
	display: none;

	@media (min-width: ${theme`screens.md`}) {
		display: block;
		width: 8px;
		height: 8px;
		background-color: ${({ status }) => (status ? '#12CD0E' : '#363636')};
	}
`
