import { forwardRef } from 'react'
import Select from 'react-select'
import { theme } from 'twin.macro'

import Label from 'components/Ui/Label'
import { Group } from './styles'
import FormError from 'components/Ui/FormError'

import type { Select as SelectType } from './types'

function getColor(state: any): string {
	if (state.isDisabled) {
		return '#9b9b9b'
	}
	return state.isSelected || state.isFocused ? '#fff' : '#676767'
}

function getBackgroundColor(state: any): string {
	if (state.isDisabled) {
		return '#fff'
	}
	return state.isSelected || state.isFocused ? '#465CFF' : '#fff'
}

const customStyles = {
	option: (provided: any, state: any) => ({
		...provided,
		color: getColor(state),
		backgroundColor: getBackgroundColor(state),
		cursor: 'pointer',
		whiteSpace: 'nowrap'
	}),
	menu: (base: any) => ({
		...base,
		zIndex: '10000',
		minWidth: '100%',
		right: '0',
		[`@media (min-width: ${theme`screens.md`})`]: {
			...base[`@media only screen and (min-width: ${theme`screens.md`})`],
			width: 'auto'
		}
	}),
	control: (_base: any, state: any) => {
		return {
			display: 'flex',
			boxShadow: state.isFocused ? '0 0 5px #465cff' : '0px 0px 4px rgba(0, 0, 0, 0.08)',
			borderRadius: '8px',
			backgroundColor: theme`colors.white`
		}
	}
}

const SelectEl = forwardRef(
	(
		{
			label,
			options = [],
			disabled = false,
			isSearchable = false,
			error,
			className,
			placeholder = '',
			isLoading = false,
			isMulti = false,
			required = false,
			handleInfiniteScroll,
			onChange,
			...rest
		}: SelectType,
		ref
	) => {
		const onMenuScrollToBottom = () => {
			handleInfiniteScroll?.()
		}

		return (
			<Group ref={ref} disabled={disabled} index="0" className={className}>
				<Label disabled={disabled} required={required}>
					{label}
				</Label>
				<Select
					styles={customStyles}
					options={options}
					isSearchable={isSearchable}
					isClearable={true}
					components={{ IndicatorSeparator: () => null }}
					isLoading={isLoading}
					onMenuScrollToBottom={onMenuScrollToBottom}
					captureMenuScroll={true}
					isMulti={isMulti}
					placeholder={placeholder}
					isDisabled={disabled}
					onChange={onChange}
					{...rest}
				/>
				{error && <FormError>{error.message}</FormError>}
			</Group>
		)
	}
)

export default SelectEl
