import { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

interface IField {
	children: ReactNode
	label: string
}

const EditorSidebarField = ({ children, label }: IField) => {
	const [isVisible, setIsVisible] = useState(false)

	return (
		<StyledField>
			<LabelContainer onClick={() => setIsVisible(!isVisible)}>
				<Label>{label}</Label>
				<FontAwesomeIcon icon={faChevronDown} />
			</LabelContainer>
			<Hidden isVisible={isVisible}>{children}</Hidden>
		</StyledField>
	)
}

const StyledField = styled.div`
	padding: 1em 0;
`

const LabelContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Label = styled.label`
	color: #333;
	font-weight: bold;
`

const Hidden = styled.div<{ isVisible: boolean }>`
	display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`

export default EditorSidebarField
