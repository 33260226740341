import styled from 'styled-components'
import { theme } from 'twin.macro'

import Table from 'components/Ui/Table'
import { Header as TableHeader } from 'components/Ui/Table'

export const StyledTable = styled(Table)`
	${TableHeader} {
		grid-template-columns: repeat(3, 1fr);

		& > span {
			text-align: left;
		}
	}
`

export const StyledItem = styled.article`
	display: grid;
	padding: 12px;
	font-size: 0.75rem;
	background-color: ${theme`colors.white`};
	border: 1px solid ${theme`colors.athensGray`};
	box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
	gap: 16px;

	@media (min-width: ${theme`screens.md`}) {
		grid-template-columns: repeat(3, 1fr);
		padding: 10px 28px 10px 7px;
		border: none;
		box-shadow: none;
		border-radius: 0;
	}
`

export const Mobile = styled.span`
	font-weight: 700;

	@media (min-width: ${theme`screens.md`}) {
		display: none;
	}
`
