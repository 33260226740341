import styled from 'styled-components'
import { theme } from 'twin.macro'

import Switch from 'components/Ui/Switch'
import Input, { IconWrapper } from 'components/Ui/Input'
import Header from 'components/Header'
import CardWrapper from 'components/Card/Wrapper'

export const Wrapper = styled.div`
	padding: 0 1rem;
	display: flex;
	column-gap: 40px;
	flex-direction: column;

	@media (min-width: ${theme`screens.lg`}) {
		flex-direction: row;
		padding: 0;
	}

	& > * {
		flex: 1;
	}
`

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;

	@media (min-width: ${theme`screens.lg`}) {
		flex-direction: row;
		max-width: 461px;
		justify-content: space-between;
		align-items: center;
	}
`

export const TagsDesc = styled.p`
	color: #989898;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	text-align: right;
`

export const StyledSwitch = styled(Switch)`
	align-self: flex-end;
	display: flex;
	gap: 10px;
`

export const StyledInput = styled(Input)`
	${IconWrapper} {
		background-color: #e3e3e3;
	}
`

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 18px;

	& > * {
		flex: 1;
	}
`
export const StyledHeader = styled(Header)`
	width: 100%;
`

export const Right = styled.div`
	margin: 20px 0 0;
	flex-direction: column;
	display: flex;
	gap: 20px;
	max-width: 461px;
	margin: 0 auto;
	width: 100%;

	@media (min-width: ${theme`screens.lg`}) {
		max-width: 800px;
		margin: 20px auto 0;
	}
`

export const Info = styled(CardWrapper)`
	padding: 30px;
	color: ${theme`colors.emperor`};
`

export const InfoRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	font-size: 0.875rem;
	font-weight: 600;
`

export const InfoHeader = styled.h3`
	font-size: 1.125rem;
	font-weight: 600;
`

export const SalesTableWrapper = styled.div`
	font-size: 12px;
	color: #676767;
	font-weight: 700;
	opacity: 1;
`
