import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import tw, { theme } from 'twin.macro'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Sidebar from 'components/Sidebar'
import { DashboardContext, StoreSettingsContext, NavContext } from 'store'
import { defaultConfig } from 'config/default'
import { useAuth, useUserRoles, useFetchData } from 'hooks'

import type { DashboardRes } from 'pages/dashboard/types'

const Dashboard = () => {
	const navCtx = useContext(NavContext)
	const storeCtx = useContext(StoreSettingsContext)
	const dashboardCtx = useContext(DashboardContext)
	const isRefreshToken = localStorage.getItem('refresh')
	const { isUser } = useUserRoles()
	const { auth } = useAuth()

	useFetchData({
		name: 'fetchStoreSettings',
		endpoint: '/settings/',
		options: {
			onSuccess: async (res: any) => {
				await storeCtx?.setStoreSettings(res.data)

				refetchWidgetData()
			},
			enabled: !isUser
		}
	})

	const { refetch: refetchWidgetData } = useFetchData({
		name: 'fetchWidgetData',
		endpoint: '/settings/widget/',
		options: {
			onSuccess: (res: any) => {
				storeCtx?.setPrimaryColor(res.data.primary_color || theme`colors.clementine`)
			},
			enabled: false
		}
	})

	useFetchData({
		name: 'fetchDashboard',
		endpoint: '/dashboard/',
		options: {
			enabled: !!auth.accessToken,
			onSuccess: (res: DashboardRes) => {
				dashboardCtx?.setDashboard(res)
			}
		}
	})

	return isRefreshToken ? (
		<Wrapper isNavOpen={navCtx?.isNavOpened || false}>
			<ContentWrapper>
				<OutletWrapper>
					<Outlet />
				</OutletWrapper>
				<Sidebar />
			</ContentWrapper>
			<ToastContainer
				position="bottom-center"
				autoClose={defaultConfig.redirectTimer}
				rtl={false}
				hideProgressBar
				closeOnClick={false}
			/>
		</Wrapper>
	) : (
		<Navigate to="/login" />
	)
}

const Wrapper = styled.section<{ isNavOpen: boolean }>`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: ${theme`colors.white`};

	@media (min-width: ${theme`screens.xl`}) {
		padding-left: 20rem;
	}

	${({ isNavOpen }) =>
		isNavOpen &&
		`
			overflow: hidden;
			height: 100vh;
		`}
`

const ContentWrapper = tw.section`
	flex
	flex-1
`

const OutletWrapper = styled.section`
	flex: 1;
	max-width: 100%;
	background-color: ${theme`colors.white`};
	padding-bottom: 1rem;

	@media (min-width: ${theme`screens.lg`}) {
		padding: 2rem 2.25rem;
	}
`

export default Dashboard
