import styled from 'styled-components'
import { theme } from 'twin.macro'

import { IFormTabs } from './types'
import Tab from './Tab'

const FormTabs = ({ tabs, activeTab, changeTab }: IFormTabs) => {
	return (
		<Wrapper>
			{tabs.map((tab: any, index: number) => (
				<Tab
					key={index}
					tab={tab}
					handleClick={() => changeTab && changeTab(tab.value)}
					isActive={activeTab === tab.value}
				/>
			))}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	overflow-x: auto;
	border: 1px solid ${theme`colors.seashell`};
	border-radius: 10px 10px 0 0;

	& > * {
		flex: 1;
	}

	& > *:nth-of-type(n + 2) {
		border-left: 1px solid ${theme`colors.seashell`};
	}
`

export default FormTabs
