import { useCallback } from 'react'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import debounce from 'lodash.debounce'

import Input, { IconWrapper } from 'components/Ui/Input'
import { defaultConfig } from 'config/default'

interface ISearchInput {
	placeholder: string
	label: string
	className?: string
	onChange: (e: any) => void
}

const SearchInput = ({ label, placeholder, className, onChange }: ISearchInput) => {
	const debouncedChangeHandler = useCallback(
		debounce(onChange, defaultConfig.searchDebounceTime),
		[]
	)

	return (
		<StyledInput
			id="search"
			name="search"
			label={label}
			placeholder={placeholder}
			icon={faMagnifyingGlass}
			onChange={debouncedChangeHandler}
			className={className}
		/>
	)
}

const StyledInput = styled(Input)`
	color: #535353;

	svg {
		color: #969696;
	}

	${IconWrapper} {
		background-color: transparent;
	}
`

export default SearchInput
