import styled from 'styled-components'
import { theme } from 'twin.macro'
import { ContextMenuItem } from 'use-context-menu'

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

export const StyledContextMenuItem = styled(ContextMenuItem)`
	padding: 8px 16px;
	width: 100%;
	min-width: 150px;
	color: #374151;
	background-color: ${theme`colors.white`};
	justify-content: center;

	&:hover {
		background-color: rgb(70, 92, 255);
		color: rgb(255, 255, 255);
	}

	&:nth-of-type(n + 2) {
		border-top: 1px solid ${theme`colors.alto`};
	}
`
