import styled from 'styled-components'
import { theme } from 'twin.macro'

import { ITabEl } from './types'

const Tab = ({ tab, isActive, handleClick }: ITabEl) => {
	return (
		<StyledButton isActive={isActive} type="button" onClick={handleClick}>
			{tab.label}
		</StyledButton>
	)
}

const StyledButton = styled.button<{ isActive: boolean }>`
	background-color: ${theme`colors.white`};
	font-size: 0.75rem;
	padding: 0.25rem;
	font-weight: 600;
	color: ${theme`colors.grayChateau`};

	${({ isActive }) =>
		isActive
			? `
			color: ${theme`colors.royalBlue`};
			border-bottom: 2px solid ${theme`colors.royalBlue`};
			background-color: ${theme`colors.athensGray2`};
		`
			: `
			border-bottom: 2px solid transparent;
		`}

	@media (min-width: ${theme`screens.sm`}) {
		font-size: 0.875rem;
		padding: 10px;
	}
`

export default Tab
