import { NavLink } from 'react-router-dom'
import { theme } from 'twin.macro'
import styled from 'styled-components'

import LanguageSwitcher from 'components/LanguageSwitcher'

export const Wrapper = styled.aside<{ isOpen: boolean }>`
	display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
	border-right: 1px solid #e3e3e3;
	flex-direction: column;
	padding: 2rem;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fdfdfd;
	justify-content: space-between;
	overflow: auto;
	gap: 2rem;
	z-index: 100;

	@media (min-width: ${theme`screens.sm`}) {
		width: 20rem;
	}

	@media (min-width: ${theme`screens.xl`}) {
		position: fixed;
		display: flex;
	}
`

export const Top = styled.div``

export const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`

export const StyledNavLink = styled(NavLink)`
	display: flex;
	border-radius: 4px;
	padding: 9px 14px;
	font-weight: 600;
	color: ${theme`colors.emperor`};
	font-size: 0.875rem;
	align-items: center;
	gap: 1rem;

	&.active {
		background-color: #eff0fe;
		color: ${theme`colors.accent`};

		& > * {
			color: ${theme`colors.accent`};
		}
	}
`

export const StyledLink = styled.a`
	display: flex;
	border-radius: 4px;
	padding: 9px 14px;
	font-weight: 600;
	color: ${theme`colors.emperor`};
	font-size: 0.875rem;
	align-items: center;
	gap: 1rem;

	&.active {
		background-color: #eff0fe;
		color: ${theme`colors.accent`};

		& > * {
			color: ${theme`colors.accent`};
		}
	}
`

export const CloseBtn = styled.button`
	position: absolute;
	right: 1rem;
	top: 1rem;

	@media (min-width: ${theme`screens.xl`}) {
		display: none;
	}
`

export const Logout = styled.button`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 9px 14px;
`

export const LogoutText = styled.span`
	color: #6d6d6d;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
`

export const StyledLanguageSwitcher = styled(LanguageSwitcher)`
	color: #6d6d6d;
	font-weight: 600;
	font-size: 14px;
	line-height: 19px;
	padding: 9px 14px;
`

export const IconContainer = styled.span<{ isActive?: boolean }>`
	width: 1.25rem;
	color: ${({ isActive }) => (isActive ? theme`colors.accent` : theme`colors.grayChateau`)};
`

export const TopContent = styled.div`
	padding: 30px 0;
`

export const PoweredBy = styled.div`
	text-align: center;
	font-size: 12px;
	margin-top: 20px;
	margin-bottom: -10px;
`

export const SidebarBalance = styled.div`
	padding: 9px 14px;
	display: flex;
	align-items: center;
	gap: 1rem;
`

export const BalanceWrapper = styled.div`
	display: flex;
	flex-direction: column;

	span {
		font-weight: 600;
	}
`

export const BalanceTitle = styled.span`
	color: ${theme`colors.dustyGray`};
	font-size: 14px;
	letter-spacing: -0.09em;
	font-weight: 400 !important;
`
