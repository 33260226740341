import { UseFormSetValue, FieldValues } from 'react-hook-form'

interface IUseSelect {
	setValue: UseFormSetValue<FieldValues>
	trigger: (data: any) => void
}

interface IHandleSelectChangeArguments {
	id: string
	value: string
	label: string
}

const useSelect = ({ setValue, trigger }: IUseSelect) => {
	const handleSelectChange = ({ id, value, label }: IHandleSelectChangeArguments) => {
		if (value) {
			setValue(id, { label: label, value: value })
		} else {
			setValue(id, undefined)
		}

		trigger([id])
	}

	return {
		handleSelectChange
	}
}

export default useSelect
