import { useQuery } from 'react-query'

import { useAxiosPrivate } from 'hooks'

interface IUseFetchData {
	query?: string | null
	name: string
	endpoint: string
	options?: any
}

export const useFetchData = ({ name, query, endpoint, options }: IUseFetchData) => {
	const axiosPrivate = useAxiosPrivate()

	const { data, isLoading, refetch, isFetching } = useQuery(
		[name, query],
		() => {
			return axiosPrivate.get(`${endpoint}${query ? `?${query}` : ''}`)
		},
		{
			refetchOnWindowFocus: false,
			...options
		}
	)

	return {
		data,
		isLoading,
		refetch,
		isFetching
	}
}
