import { Outlet } from 'react-router-dom'

import { useAuth, useFetchData } from 'hooks'

const PersistLogin = () => {
	const { setAuth } = useAuth()

	useFetchData({
		name: 'fetchMe',
		endpoint: '/me/',
		options: {
			onSuccess: (res: any) => {
				const me = res.data

				setAuth((prev: any) => ({ ...prev, user: me }))
			}
		}
	})

	return <Outlet />
}

export default PersistLogin
