import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Wrapper = styled.div`
	margin-bottom: 35px;
	position: relative;

	table {
		position: static;
	}

	.th,
	.td {
		input[type='checkbox'] {
			height: 17px;
			width: 17px;
		}
	}
`

export const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: ${theme`screens.md`}) {
		justify-content: flex-end;
	}
`
