import styled from 'styled-components'
import { theme } from 'twin.macro'

import Header from 'components/Header'
import Table from 'components/Ui/Table'
import Table2 from 'components/Ui/Table2'
import { Header as TableHeader } from 'components/Ui/Table'

export const StyledHeader = styled(Header)`
	margin-bottom: 2rem;
`

export const StyledTable = styled(Table)`
	${TableHeader} {
		grid-template-columns: repeat(7, 1fr) 15px;

		& > span:first-of-type {
			text-align: left;
		}

		& > span:nth-of-type(2) {
			text-align: center;
		}
	}
`

export const StyledTable2 = styled(Table2)`
	height: 65vh;

	@media (min-width: ${theme`screens.md`}) {
		height: calc(100vh - 59px - 164px);
	}

	@media (min-width: ${theme`screens.lg`}) {
		height: calc(100vh - 2rem - 27px - 90px - 125px);
	}

	@media (min-width: 1667px) {
		height: calc(100vh - 2rem - 27px - 215px);
	}
`
