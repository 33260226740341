import styled from 'styled-components'
import { theme } from 'twin.macro'

import Header from 'components/Header'

export const StyledHeader = styled(Header)`
	width: 100%;
`

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;

	@media (min-width: ${theme`screens.lg`}) {
		flex-direction: row;
		max-width: 461px;
		justify-content: space-between;
		align-items: center;
	}
`

export const Row = styled.div`
	gap: 10px;
	display: flex;
	flex-direction: column;

	@media (min-width: ${theme`screens.md`}) {
		justify-content: space-between;
		flex-direction: row;

		& > * {
			flex: 1;
		}
	}
`

export const Fieldset = styled.fieldset`
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 10px;
`

export const Legend = styled.legend`
	/* border: 1px solid lightgray; */
`
