import styled from 'styled-components'
import { theme } from 'twin.macro'

import Select from 'components/Ui/Select'

import type { IFilters, IFilter } from './types'

const Filters = ({ filters, disableActions, className, handleFilter }: IFilters) => {
	return (
		<Wrapper className={className}>
			<Selects>
				{filters.map((filter: IFilter) => (
					<Select
						key={filter.id}
						{...filter}
						disabled={filter.id === 'actions' && disableActions}
						onChange={(data) => handleFilter({ id: filter.id, ...data })}
					/>
				))}
			</Selects>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
		align-items: center;
		gap: 30px;
	}
`

export const Selects = styled.div`
	display: flex;
	gap: 20px;
`

export default Filters
