import { ReactNode } from 'react'
import styled from 'styled-components'

interface IEllipsisText {
	children: ReactNode
	textCenter?: boolean | undefined
}

export const EllipsisText = ({ children, textCenter }: IEllipsisText) => {
	return (
		<NameContainer textCenter={textCenter}>
			<NameWrapper>{children}</NameWrapper>
		</NameContainer>
	)
}

const NameContainer = styled.div<{ textCenter: boolean | undefined }>`
	width: 100%;
	display: table;
	table-layout: fixed;
	text-align: ${({ textCenter }) => (textCenter ? 'center' : 'left')};
`

const NameWrapper = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: break-all;
	word-wrap: break-word;
	display: table-cell;
`
