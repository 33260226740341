import { createContext, ReactNode, useState } from 'react'

interface INavContext {
	isNavOpened: boolean
	toggleNavOpened: (value: boolean) => void
}

interface INavContextProvider {
	children: ReactNode
}

export const NavContext = createContext<INavContext | null>(null)

export const NavContextProvider = ({ children }: INavContextProvider) => {
	const [isNavOpened, toggleNavOpened] = useState(false)

	const value = {
		isNavOpened,
		toggleNavOpened
	}

	return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}
