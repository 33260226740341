import styled from 'styled-components'
import { theme } from 'twin.macro'

import Header from 'components/Header'

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;

	@media (min-width: ${theme`screens.lg`}) {
		flex-direction: row;
		max-width: 461px;
		justify-content: space-between;
		align-items: center;
	}
`

export const StyledHeader = styled(Header)`
	width: 100%;
`
