import { forwardRef, Ref } from 'react'

import Label from 'components/Ui/Label'
import { Group, FieldWrapper, IconWrapper, Icon, StyledTextarea, WordCount, Flex } from './styles'
import FormError from 'components/Ui/FormError'

import type { Textarea as TextareaType } from './types'

const Textarea = forwardRef(
	(
		{
			id,
			label,
			name,
			icon,
			placeholder,
			onChange,
			disabled,
			className,
			error,
			letters,
			maxLetterCount,
			required
		}: TextareaType,
		ref: Ref<HTMLTextAreaElement>
	) => {
		return (
			<Group className={className}>
				<Flex>
					{label && (
						<Label htmlFor={id} required={required}>
							{label}
						</Label>
					)}
					{maxLetterCount && (
						<WordCount>
							{letters}/{maxLetterCount}
						</WordCount>
					)}
				</Flex>
				<FieldWrapper>
					{icon && (
						<>
							{typeof icon === 'string' ? (
								<IconWrapper>{icon}</IconWrapper>
							) : (
								<IconWrapper>
									<Icon icon={icon} />
								</IconWrapper>
							)}
						</>
					)}
					<StyledTextarea
						id={id}
						name={name}
						placeholder={placeholder}
						onChange={onChange}
						ref={ref}
						withIcon={icon}
						disabled={disabled}
						// step={step}
					/>
				</FieldWrapper>
				{error && <FormError>{error.message}</FormError>}
			</Group>
		)
	}
)

export default Textarea
