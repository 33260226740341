import { createContext, ReactNode, useState } from 'react'

interface IStoreSettingsContextProvider {
	children: ReactNode
}

interface IStoreSettingsContext {
	storeSettings: any
	primaryColor: string | null
	setStoreSettings: (data: any) => void
	setPrimaryColor: (color: string | null) => void
}

export const StoreSettingsContext = createContext<IStoreSettingsContext | null>(null)

export const StoreSettingsContextProvider = ({ children }: IStoreSettingsContextProvider) => {
	const [storeSettings, setStoreSettings] = useState()
	const [primaryColor, setPrimaryColor] = useState<string | null>('')

	const value = {
		storeSettings,
		primaryColor,
		setStoreSettings,
		setPrimaryColor
	}

	return <StoreSettingsContext.Provider value={value}>{children}</StoreSettingsContext.Provider>
}
