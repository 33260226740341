import { useState } from 'react'
import styled from 'styled-components'
import tw, { theme } from 'twin.macro'

import { useTranslation } from 'libs'
import { LanguageIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

import { Wrapper, IconContainer } from './styles'

import type { LanguageSwitcherProps } from './types'

const LanguageSwitcher = ({ className, dropdown = false, icon = false }: LanguageSwitcherProps) => {
	const { i18n, t } = useTranslation()
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	const [isOpen, setOpen] = useState(false)
	const languages = [
		{
			label: t('languages.is'),
			code: 'is'
		},
		{
			label: t('languages.en'),
			code: 'en'
		},
		{
			label: t('languages.dk'),
			code: 'dk'
		},
		{
			label: t('languages.se'),
			code: 'se'
		}
	]

	return (
		<>
			{dropdown ? (
				<>
					<Label onClick={() => setOpen(!isOpen)} isActive={isOpen}>
						<LabelContent>
							{icon ? (
								<IconContainer>
									<LanguageIcon />
								</IconContainer>
							) : null}
							<span>{languages.find((language) => language.code === i18n.language)?.label}</span>
						</LabelContent>
						{isOpen ? <StyledChevronUpIcon /> : <StyledChevronDownIcon />}
					</Label>
					{isOpen && (
						<Content>
							{languages.map((language) => (
								<Subtitle key={language.code} onClick={() => changeLanguage(language.code)}>
									{language.label}
								</Subtitle>
							))}
						</Content>
					)}
				</>
			) : (
				<Wrapper className={className}>
					{icon && (
						<IconContainer>
							<LanguageIcon />
						</IconContainer>
					)}
					{languages.map((language, index) => (
						<>
							<button
								key={language.code}
								type="button"
								onClick={() => changeLanguage(language.code)}>
								{language.code.toUpperCase()}
							</button>
							{index < languages.length - 1 ? <span>/</span> : null}
						</>
					))}
				</Wrapper>
			)}
		</>
	)
}

const Label = styled.span<{ isActive: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	border-radius: 4px;
	padding: 12px 14px;
	font-weight: 600;
	font-size: 0.875rem;
	background-color: ${({ isActive }) => (isActive ? theme`colors.zumthor` : theme`colors.white`)};
	color: ${({ isActive }) => (isActive ? theme`colors.accent` : theme`colors.emperor`)};
`

const LabelContent = tw.span`flex gap-4`

const StyledChevronUpIcon = tw(ChevronUpIcon)`w-lg`

const StyledChevronDownIcon = tw(ChevronDownIcon)`w-lg`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px 14px;
`

const Subtitle = styled.span`
	color: ${theme`colors.grayChateau`};
	font-size: 13px;
	padding: 7px 16px;
	border-left: 1px solid ${theme`colors.iron`};
	margin: 4px 0;
	cursor: pointer;
`

export default LanguageSwitcher
