import styled from 'styled-components'
import { theme } from 'twin.macro'

import CtaLink from 'components/Ui/CtaLink'

export const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100%;
	padding: 0 1rem;
`

export const Content = styled.div`
	text-align: center;
`

export const Title = styled.h1`
	color: ${theme`colors.tundora`};
	font-size: 5.25rem;
	font-weight: 600;
`

export const Text = styled.p`
	color: ${theme`colors.doveGray`};
	font-size: 1.5rem;
`

export const StyledCtaLink = styled(CtaLink)`
	max-width: 185px;
	margin: 1.5rem auto 0;
`
