import { useState, CSSProperties } from 'react'
import styled from 'styled-components'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'

import Label from 'components/Ui/Label'

interface IColorPicker {
	label: string
	color: string
	onChange: (data: any) => void
}

const ColorPicker = ({ label, color, onChange }: IColorPicker) => {
	const [displayColorPicker, setDisplayColorPicker] = useState(false)

	const styles = reactCSS({
		default: {
			color: {
				width: '18px',
				height: '18px',
				borderRadius: '50%',
				background: color
			},
			swatch: {
				padding: '10px',
				background: '#fff',
				borderRadius: '8px',
				boxShadow: '0px 0px 4px rgb(0 0 0 / 8%)',
				display: 'flex',
				cursor: 'pointer',
				width: '100%',
				justifyContent: 'space-between',
				alignItems: 'center',
				fontSize: '0.75rem'
			},
			popover: {
				position: 'absolute',
				zIndex: '2'
			} as CSSProperties,
			cover: {
				position: 'fixed',
				top: '0px',
				right: '0px',
				bottom: '0px',
				left: '0px'
			} as CSSProperties
		}
	})

	const showColorPicker = () => setDisplayColorPicker(!displayColorPicker)
	const closeColorPicker = () => setDisplayColorPicker(false)

	return (
		<Group>
			<Label>{label}</Label>
			<div>
				<div style={styles.swatch} onClick={showColorPicker}>
					<span>{color}</span>
					<div style={styles.color} />
				</div>
				{displayColorPicker ? (
					<div style={styles.popover}>
						<div style={styles.cover} onClick={closeColorPicker} />
						<SketchPicker color={color} onChange={onChange} />
					</div>
				) : null}
			</div>
		</Group>
	)
}

const Group = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.625rem;
`

export default ColorPicker
