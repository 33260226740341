import { createContext, ReactNode, useState } from 'react'

import type { DashboardRes } from 'pages/dashboard/types'

type DashboardContextProviderProps = {
	children: ReactNode
}

type DashboardContextProps = {
	dashboard: DashboardRes | null
	setDashboard: (data: DashboardRes) => void
}

export const DashboardContext = createContext<DashboardContextProps | null>(null)

export const DashboardContextProvider = ({ children }: DashboardContextProviderProps) => {
	const [dashboard, setDashboard] = useState<DashboardRes | null>(null)

	const value = {
		dashboard,
		setDashboard
	}

	return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}
