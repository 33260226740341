export const defaultConfig = {
	maxFileSize: 20971520, // 20MB
	redirectTimer: 2500,
	exportFormatOptions: [
		{
			value: 0,
			label: 'CSV'
		},
		{
			value: 1,
			label: 'XLS'
		},
		{
			value: 2,
			label: 'XLSX'
		},
		{
			value: 3,
			label: 'TSV'
		},
		{
			value: 4,
			label: 'ODS'
		},
		{
			value: 5,
			label: 'JSON'
		},
		{
			value: 6,
			label: 'YAML'
		},
		{
			value: 7,
			label: 'HTML'
		}
	],
	searchDebounceTime: 300,
	maxProductNameLength: 30,
	maxProductSizeLength: 10
}
