import tw from 'twin.macro'

import StatisticsCard from 'components/Card/Statistics'

export const Summaries = tw.div`grid gap-3 grid-cols-2 md:grid-cols-4 md:gap-6`

export const Lists = tw.div`flex gap-3 flex-wrap sm:[&>div]:flex-1`

export const Content = tw.div`flex flex-col gap-3 my-8 px-6 lg:p-0 lg:gap-8`

export const BoothsList = tw.div`md:w-1/2`

export const Booth = tw(StatisticsCard)`rounded-xl py-6 px-8`
