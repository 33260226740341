import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div`
	display: grid;
	text-align: center;
	align-items: center;
	padding: 12px;
	font-size: 12px;
	font-weight: 600;
	color: #767676;
	background-color: #fff;
	border: 1px solid #e5e7eb;
	box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
	position: relative;

	& > *:nth-child(2) {
		text-align: left;
	}

	span {
		word-break: break-all;
	}
`

export const ImageElement = styled.div`
	display: flex;
	gap: 18px;
	grid-row-start: 1;
	grid-row-end: 4;
	grid-column-end: 2;
`

export const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: start;
	gap: 10px;
`

export const InfoDetails = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
`

export const Date = styled(Link)`
	padding: 2px 4px;
	border-radius: 7px;
	grid-column-start: 2;
	grid-row-start: 1;
	background-color: #36b059;
	color: #fff;
	justify-self: end;
	white-space: nowrap;
	font-size: 9px;
`

export const Name = styled(Link)`
	font-size: 13px;
	color: #535353;
`

export const InfoMobile = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 11px;
`

export const InfoMobileData = styled.div`
	span {
		font-weight: 700;
	}
`

export const Total = styled.span`
	grid-column-start: 2;
	grid-row-start: 3;
	justify-self: end;
	font-size: 1.125rem;
	color: ${theme`colors.tundora`};
`

export const PriceDetails = styled.span`
	display: block;
	text-align: right;
	font-size: 0.75rem;
	color: ${theme`colors.gray`};
`
