import { ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from 'twin.macro'

interface IPageWrapper {
	children: ReactNode
}

const PageWrapper = ({ children }: IPageWrapper) => {
	return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.div`
	padding: 0 1rem;

	@media (min-width: ${theme`screens.lg`}) {
		padding: 0;
	}
`

export default PageWrapper
