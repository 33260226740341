import { StatusWrapper, StatusIndicator } from './styles'

interface IStatus {
	status: boolean
}

const BookingStatus = ({ status }: IStatus) => {
	return (
		<StatusWrapper status={status}>
			<StatusIndicator status={status} />
			<span>{status ? 'active' : 'expired'}</span>
		</StatusWrapper>
	)
}

export default BookingStatus
