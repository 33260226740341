import styled from 'styled-components'

export const Wrapper = styled.article`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	padding: 10px 28px 10px 7px;
	column-gap: 16px;
	font-size: 12px;
	font-weight: 600;
	color: #989898;
	background-color: #fff;
	border: 1px solid #e5e7eb;
	box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
	gap: 0.5rem;
`

export const Mobile = styled.span`
	font-weight: 700;
`

export const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;

	& > span {
		flex: 1;
	}

	& > span:last-of-type {
		text-align: left;
	}
`
