import { useContext } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from 'store/AuthContext'

export const useLogout = () => {
	const { setAuth } = useContext(AuthContext)
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const logout = () => {
		queryClient.removeQueries()
		setAuth({})
		localStorage.removeItem('refresh')
		navigate('/login')
	}

	return logout
}
