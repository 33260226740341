import styled from 'styled-components'
import { theme } from 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const BtnIcon = styled(FontAwesomeIcon)`
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
	font-size: 1.25rem;
	color: ${theme`colors.dustyGray`};
	font-size: 1rem;
`

export const Title = styled.h3`
	font-size: 1.125rem;
	font-weight: 600;
`

export const Header = styled.header`
	padding: 1rem;
`
