interface IDownloadAs {
	file: Blob
	name: string
	format: string
}

export const downloadAs = ({ file, name, format }: IDownloadAs) => {
	const url = window.URL.createObjectURL(new Blob([file]))
	const link = document.createElement('a')
	link.setAttribute('id', 'fileLink')
	link.href = url
	link.setAttribute('download', `${name}.${format}`)
	document.body.appendChild(link)
	link.click()
	document.getElementById('fileLink')?.remove()
}
