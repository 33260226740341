import styled from 'styled-components'

import Form from 'components/Ui/Form'
import { Button } from 'components/Ui'
import Switch from 'components/Ui/Switch'

export const StyledForm = styled(Form)`
	max-width: 560px;
	gap: 0;
`

export const Row = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 35px;

	& > * {
		flex: 1;
	}
`

export const StyledButton = styled(Button)`
	font-size: 0.875rem;
`

export const StyledSwitch = styled(Switch)``

export const SubSwitch = styled.div`
	padding-left: 1rem;
`

export const Fieldset = styled.fieldset`
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`

export const Legend = styled.legend`
	/* border: 1px solid lightgray; */
`

export const Label = styled.label`
	font-size: 12px;
	color: #676767;
	font-weight: 700;
	opacity: 1;
`
