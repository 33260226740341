import styled from 'styled-components'
import { theme } from 'twin.macro'

import Table from 'components/Ui/Table'
import Filters, { Selects } from 'components/Filters'
import SearchInput from 'components/Ui/SearchInput'
import SortSelect from 'components/Ui/Select/Sort'
import Table2 from 'components/Ui/Table2'
import { Header as TableHeader } from 'components/Ui/Table'

export const StyledTable = styled(Table)<{ isIceland: boolean }>`
	${TableHeader} {
		grid-template-columns: ${({ isIceland }) =>
			isIceland ? ' 13px repeat(8, 1fr) 15px' : '13px repeat(7, 1fr) 15px'};

		& > span:first-of-type {
			text-align: left;
		}

		& > span:nth-of-type(2) {
			text-align: center;
		}
	}
`

export const FiltersContainer = styled.div`
	display: flex;
	gap: 20px;
	width: 100%;

	@media (min-width: ${theme`screens.lg`}) {
		width: auto;
	}
`

export const StyledSearchInput = styled(SearchInput)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: flex;
	}
`

export const FiltersModalContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`

export const ModalFilters = styled(Filters)`
	display: flex;

	${Selects} {
		width: 100%;

		& > div {
			width: 100%;
		}
	}
`

export const DesktopSortSelect = styled(SortSelect)`
	display: none;

	@media (min-width: ${theme`screens.lg`}) {
		display: block;
	}
`

export const StyledTable2 = styled(Table2)`
	height: 65vh;

	@media (min-width: ${theme`screens.md`}) {
		height: calc(100vh - 59px - 164px);
	}

	@media (min-width: ${theme`screens.lg`}) {
		height: calc(100vh - 2rem - 27px - 90px - 125px);
	}

	@media (min-width: 1667px) {
		height: calc(100vh - 2rem - 27px - 215px);
	}
`
