import styled from 'styled-components'
import { theme } from 'twin.macro'

export const Menu = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	@media (min-width: ${theme`screens.md`}) {
		display: flex;
		align-items: center;
		justify-content: center;
		position: static;
		cursor: pointer;
		width: 15px;
		height: 15px;
	}
`

export const MenuItems = styled.div`
	position: absolute;
	right: 0;
	font-size: 14px;
	color: #374151;
	background-color: ${theme`colors.white`};
	z-index: 100;
	top: auto;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: space-between;
	border-top: 1px solid ${theme`colors.alto`};

	@media (min-width: ${theme`screens.md`}) {
		border-top: none;
		left: auto;
		min-width: 150px;
		display: block;
		bottom: auto;
		border-radius: 6px;
		top: 100%;
		padding: 4px 0;
		max-height: none;
		height: auto;
		display: flex;
		flex-direction: column;
		box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
			0px 0px 0px rgba(0, 0, 0, 0.08);
	}

	& > * {
		border-left: 1px solid ${theme`colors.alto`};

		@media (min-width: ${theme`screens.md`}) {
			border-left: none;
		}
	}
`

export const MenuOption = styled.button<{
	borderTop?: boolean
	borderBottom?: boolean
	disabled?: boolean
}>`
	padding: 8px 16px;
	width: 100%;

	${({ borderTop }) => borderTop && `border-top: 1px solid ${theme`colors.alto`}`};
	${({ borderBottom }) => borderBottom && `border-bottom: 1px solid ${theme`colors.alto`}`};
	${({ disabled }) => disabled && 'color: gray'};

	@media (min-width: ${theme`screens.md`}) {
		line-height: unset;
	}

	&:hover {
		background-color: #465cff;
		color: #fff;
		${({ disabled }) => disabled && 'color: gray; background-color: white'};
	}
`

export const Overlay = styled.div`
	display: block;
	background: rgba(255, 255, 255, 0.5);
	width: 100%;
	height: 100%;
	position: relative;
`
