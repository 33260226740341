import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Link } from 'react-router-dom'

export const Wrapper = styled.article`
	position: relative;
	display: grid;
	column-gap: 16px;
	text-align: center;
	align-items: center;
	padding: 12px;
	font-size: 12px;
	font-weight: 600;
	color: #767676;
	background-color: ${theme`colors.white`};
	border: 1px solid ${theme`colors.athensGray`};
	box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.12);
	border-radius: 3px;
`

export const ImageElement = styled.div`
	display: flex;
	gap: 18px;
	grid-row-start: 1;
	grid-row-end: 4;
	grid-column-end: 2;
	text-align: left;
`

export const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

export const InfoDetails = styled.div`
	display: flex;
	flex-direction: column;
`

export const Name = styled(Link)`
	font-size: 13px;
	color: #535353;
`

export const Price = styled.span`
	grid-row-start: 3;
	grid-row-end: 4;
	grid-column-start: 2;
	justify-self: end;
	color: #454545;
	font-weight: 600;
	font-size: 18px;
	text-align: right;
`

export const SoldMobile = styled.span`
	font-weight: 600;
	font-size: 13px;
`

export const SoldCountMobile = styled.span`
	font-weight: 700;
`

export const PriceDetails = styled.span`
	color: #828282;
	display: block;
	font-size: 12px;
`
