import { ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Styles } from 'react-modal'
import { t } from 'i18next'

import { Modal } from 'components/Ui'
import { Button } from 'components/Ui'
import { customModalStyles } from 'styles/general'

interface IDeleteConfirmModal {
	isOpened: boolean
	title: string
	children?: ReactNode
	closeModal: () => void
	confirm: () => void
}

const customStyles: Styles = {
	...customModalStyles,
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '350px',
		backgroundColor: '#fff',
		width: '100%',
		padding: '0',
		border: 'none'
	}
}

const DeleteConfirmModal = ({
	isOpened,
	closeModal,
	title,
	confirm,
	children
}: IDeleteConfirmModal) => {
	return (
		<Modal isOpened={isOpened} closeModal={closeModal} title={title} customStyles={customStyles}>
			{children && <Content>{children}</Content>}
			<Buttons>
				<Button
					text={t('category.delete.modal.button.cancel')}
					styleType="cancel"
					onClick={closeModal}
				/>
				<Button
					text={t('category.delete.modal.button.delete')}
					styleType="delete"
					onClick={confirm}
				/>
			</Buttons>
		</Modal>
	)
}

const Buttons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 1rem;
	background-color: ${theme`colors.mineShaft`};
	padding: 1rem 2rem;
`

const Content = styled.div`
	padding: 0 1rem 1rem;
	font-size: 0.875rem;
	color: ${theme`colors.scorpion`};
`

export default DeleteConfirmModal
